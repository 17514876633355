export const initialClaim = {
  description: "",
  vat: false,
  non_vat: false,
  type: null,
  currency: "USD",
  amount: "",
  attachment: [],
  date: "",
  remarks: "",
};

export const InitialTimeSheetDataFn = (currentWeek, hasHolidays = false) => {
  return [
    {
      client: hasHolidays ? "ap" : null,
      engagement: hasHolidays ? "ap": null,
      task: null,
      budget: "",
      actual_to_date: "",
      currentWeek: currentWeek.format("YYYY-MM-DD"),
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
      sun: "",
      sum: "",
      claims: [],
      ncClient: null,
      subEngagement: null,
      remarks: "",
      status: "pending",
    },
  ];
};
