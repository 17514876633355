import Button from "atoms/Button";
import { uniqBy } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "atoms/Table";
import EditResourceModal from "./EditResourceModal";
import queryKeys from "common/queryKey";
import { useScrollFetch2 } from "hooks/useScrollFetch2";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, getUserList } from "redux/selectors";
import ViewResourceModal from "./ViewResourceModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "antd";
import {
  setResourceData,
  setTempEngagementFormData,
  toggleProcessingModal,
} from "redux/actions";
import { Form, notification } from "antd";
import { currencyAmount } from "utils/functions";
import { findWeeksInRange } from "utils/Date";
import { useUpdate } from "hooks/useUpdate";

const projectRoleOrder = [
  "Partner",
  "QA Partner",
  "Person Incharge",
  "Is Auditor",
  "IS Auditor",
  "Member"
];

const sortDataByProjectRole = (data) => {
  return data?.sort((a, b) => {
    // Get the index of projectRole in the predefined order
    const indexA = projectRoleOrder.indexOf(a.projectRole);
    const indexB = projectRoleOrder.indexOf(b.projectRole);

    // If projectRole is not found in the predefined order, put it at the end
    return (
      (indexA === -1 ? projectRoleOrder.length : indexA) -
      (indexB === -1 ? projectRoleOrder.length : indexB)
    );
  });
};

const ResourcePlan = ({
  engagementData,
  tempEngagementFormData = [],
  viewOnly = false,
  engagementRefetch,
  update,
  setDeletedbookedUserId,
  addResourceandSubDisabled = false,
  setBudjetChargeout
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const form = Form.useFormInstance();
  const userList = useSelector(getUserList);

  const isAddDisable = (user) => {
    let isPartnerOrManager = false;

    if (engagementData?.engagementId) {
      isPartnerOrManager =
        user === engagementData?.stakeHolder?.personIncharge ||
        user === engagementData?.stakeHolder?.partner ||
        user === engagementData?.stakeHolder?.relationshipPartner ||
        user === engagementData?.stakeHolder?.isauditor;
    } else {
      isPartnerOrManager =
        user === tempEngagementFormData?.person_incharge ||
        user === tempEngagementFormData?.partner ||
        user === tempEngagementFormData?.relationship_partner ||
        user === tempEngagementFormData?.isauditor;
    }

    return isPartnerOrManager;
  };

  const engagmentSurveyModal = async (record) => {
    try {
      if (!engagementData?.checkList?.clientsurvey) {
        Modal.info({
          icon: "",
          content: (
            <div style={{ textAlign: "center" }}>
              <p>
                You cannot create a engagement survey at this time as client
                survey is still pending.
              </p>
            </div>
          ),
          okButtonProps: { style: { display: "none" } },
          cancelButtonProps: { style: { display: "none" } },
          maskClosable: true,
        });
      } else {
        dispatch(setResourceData(record));
        history.push(
          `/engagement/${record.engagementId}/engagementappraisal/${record.resourceId}`
        );
      }
    } catch (error) {
      console.error("[engagmentSurveyModal] error -->", error);
    }
  };

  const resourceColumns = [
    {
      title: "Name",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (val) => {
        return <span className="text-primary">{val || "-"}</span>;
      },
      onCell: (record) => ({
        onClick: () => {
          const disableBtn = isAddDisable(record.userId);

          console.log("record--->", record);
            
          // if (!disableBtn && engagementData?.engagementId) {
          if (!viewOnly && !addResourceandSubDisabled) {
            setSelectedRow(record);
            setShowEdit(true);
          }
        },
        style: { cursor: "pointer" },
      }),
    },
    {
      title: "Role",
      dataIndex: "projectRole",
      key: "projectRole",
    },
    {
      title: "Start Date",
      dataIndex: "actualBookedStartDate",
      key: "actualBookedStartDate",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return date ? `${dateFormat}` : "-";
      },
    },
    {
      title: "Finish Date",
      dataIndex: "actualBookedEndDate",
      key: "actualBookedEndDate",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return date ? `${dateFormat}` : "-";
      },
    },
    {
      title: "Mandays",
      dataIndex: "personDay",
      key: "personDay",
      render: (val) => {
        return <span className="text-primary">{val || "-"}</span>;
      },
      onCell: (record) => ({
        onClick: () => {
          if (!viewOnly) {
            setSelectedRow(record);
            setViewResourceModal(true);
          }
        },
        style: { cursor: "pointer" },
      }),
    },
    {
      title: "Charge out / hr",
      dataIndex: "chargeOutRate",
      key: "chargeOutRate",
      render: (val) => {
        return (
          <span className="text-primary">
            {currencyAmount("$", val) || "0"}
          </span>
        );
      },
      onCell: (record) => ({
        onClick: () => {},
        style: { cursor: "pointer" },
      }),
    },
    {
      title: "Total Booked Hours",
      dataIndex: "totalHours",
      key: "totalHours",
      render: (val) => {
        return <span className="text-primary">{val.toFixed(2) || "0"}</span>;
      },
      onCell: (record) => ({
        onClick: () => {},
        style: { cursor: "pointer" },
      }),
    },
    {
      title: "Total PCO",
      dataIndex: "totalPCU",
      key: "totalPCU",
      render: (val) => {
        return (
          <span className="text-primary">
            {currencyAmount("$", val) || "0"}
          </span>
        );
      },
      onCell: (record) => ({
        onClick: () => {},
        style: { cursor: "pointer" },
      }),
    },
    ...(engagementData?.engagementId
      ? [
          {
            title: "Engagement Appraisal",
            dataIndex: "engagementAppraisalAvg",
            key: "engagementAppraisalAvg",
            render: (va, record) => (
              <span className="text-primary">
                {record?.engagementAppraisal?.engagementAppraisalAvg ?? "0"}
              </span>
            ),
            onCell: (record) => ({
              onClick: () => {
                engagmentSurveyModal(record);
              },
              style: { cursor: "pointer" },
            }),
          },
          {
            title: "Effort",
            dataIndex: "effortPercentage",
            key: "effortPercentage",
            render: (effortPercentage) => {
              return effortPercentage ? `${effortPercentage}%` : "0";
            },
          },
        ]
      : []),
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    !engagementData?.engagementId && {
      //? while creating engagement we can delete the resource if need
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    engagementData?.engagementId && {
      title: "Action",
      dataIndex: "actionAssigned",
      key: "actionAssigned",
    },
  ].filter(Boolean);
  const [showEdit, setShowEdit] = React.useState(false);
  // console.log("bookedUserList--->", bookedUserList)
  const [bookedUserList, setBookedUserList] = React.useState([]);
  const [actualBookedUserList, setActualBookedUserList] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const userInfo = useSelector((state) => getUserInfo(state));
  const [viewResourceModal, setViewResourceModal] = useState(false);

  const [activeTab, setActiveTab] = React.useState("resource");


  const queryParams = new URLSearchParams();
  queryParams.append("engagementid", engagementData?.engagementId);
  queryParams.append("managingOffice", engagementData?.client?.managingOffice);

  const queryString = queryParams.toString();

  const { getResourcePlanDataById, updateResource } = queryKeys;

  const { mutate: updateData, isLoading: isUpdating } = useUpdate(
    updateResource.key,
    updateResource.url
  );

  const { key, url } = getResourcePlanDataById(
    engagementData?.engagementId,
    `?${queryString}`
  );

  const { data, lastElementRef, isLoading, refetch } = useScrollFetch2(key, {
    endPoint: url,
    queryParam: "lastEvaluatedKey",
  });

  const dataList = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      if (page?.response?.data === undefined) return acc;
      return [...acc, ...page?.response?.data];
    }, []);
  }, [data]);

  useEffect(() => {
    const flattenedArray = dataList;
    setActualBookedUserList(flattenedArray);
    setBookedUserList(uniqBy(flattenedArray, "resourceId"));
  }, [dataList]);

  const filterContractUsers = () => {
    if (activeTab === "resource") {
      return bookedUserList?.filter(
        (user) => user.employeementType !== "contract"
      );
    } else {
      return bookedUserList?.filter(
        (user) => user.employeementType === "contract"
      );
    }
  };

  const sortedDataSource = sortDataByProjectRole(
    engagementData?.engagementId
      ? filterContractUsers()
      : tempEngagementFormData?.resourceList
  );

  const generateSchedule = (startDate, endDate) => {
    const schedule = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);

    while (currentDate.isSameOrBefore(end, "day")) {
      const dayOfWeek = currentDate.day();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const dateString = currentDate.format("MMM DD");
        const fullDateFormatted = currentDate.format("YYYY-MM-DD");
        schedule.push({
          date: dateString,
          fullDate: moment(fullDateFormatted),
        });
      }
      currentDate.add(1, "day");
    }

    return schedule;
  };

  useEffect(() => {
    dispatch(toggleProcessingModal(isUpdating));
  }, [isUpdating]);

  const handleEditResource = async (selectedResource) => {
    const startDate = selectedResource?.actualBookedStartDatete;
    const endDate = selectedResource?.actualBookedEndDate;
    const weeksInRange = findWeeksInRange(startDate, endDate);
    const prevWeekList = findWeeksInRange(
      selectedResource?.actualBookedStartDate || moment(),
      selectedResource?.actualBookedEndDate || moment()
    );
    const prevWeekListweekIds = prevWeekList?.map((item) => item.weekId);
    const payload = {
      userId: selectedResource?.userId,
      employeeName: selectedResource?.employeeName,
      employeeId: selectedResource?.employeeId,
      accessRole: selectedResource?.accessRole,
      projectRole: selectedResource?.projectRole,
      actualBookedStartDate: moment(
        selectedResource?.actualBookedStartDate
      ).format("YYYY-MM-DD"),
      actualBookedEndDate: moment(selectedResource?.actualBookedEndDate).format(
        "YYYY-MM-DD"
      ),
      engagementId: selectedResource?.engagementId,
      engagementName: selectedResource?.engagementName,
      clientId: selectedResource?.clientId,
      effort: selectedResource?.effort,
      remarks: selectedResource?.remark,
      personDay: selectedResource?.totalPersonDays,
      resourceExtRate: selectedResource?.resourceExtRate,
      status: "Un Assigned",
      engagementStartDate: selectedResource?.engagementStartDate,
      engagementEndDate: selectedResource?.engagementEndDate,
      weekList: weeksInRange,
      previousWeekList: prevWeekListweekIds,
      resourceId: selectedResource?.resourceId,
      justification: selectedResource?.justification,
      unassignedDate: moment().format("YYYY-MM-DD"),
    };
    updateData(payload, {
      onSuccess: () => {
        notification.success({
          message: "Successfully Un Assigned",
        });
        refetch();
      },
    });
  };

  const resourceData = sortedDataSource?.map((resource) => {
    const user = userList?.find((user) => {
      return user.userid === resource.userId;
    });

    const chargeoutrate = user?.chargeoutrate || 0;

    const schedule = generateSchedule(
      resource.actualBookedStartDate,
      resource.actualBookedEndDate
    );

    const totalHours = schedule?.reduce((acc, _curr) => {
      const value = parseFloat(
        (
          (parseFloat(resource.personDay) * 8) /
          (schedule?.length || 1)
        ).toFixed(2)
      );
      return acc + value;
    }, 0);

    const handleDeleteResource = (resource) => {
      const formValues = form.getFieldsValue(true);
      if (update) {
        // while updating/Editing the Engagement we can now delete the resource

        const updatedBookedUserList = bookedUserList?.filter(
          (item) => item.userId !== resource.userId
        );

        setDeletedbookedUserId((prev) => [
          ...prev,
          resource.resourceId,
        ]);

        setBookedUserList(updatedBookedUserList);
      } else {
        const updatedResourceList =
          tempEngagementFormData?.resourceList?.filter(
            (item) => item.userId !== resource.userId
          );

        const updatedFormValues = {
          ...formValues,
          resourceList: updatedResourceList,
        };

        dispatch(setTempEngagementFormData(updatedFormValues));
      }
    };

    const disableBtn = isAddDisable(resource.userId);

    return {
      ...resource,
      chargeOutRate: chargeoutrate,
      totalHours: totalHours,
      totalPCU: parseFloat(resource?.personDay ?? 0) * 8 * chargeoutrate,
      action: disableBtn ? null : (
        <ActionRender
          resource={resource}
          handleDeleteResource={handleDeleteResource}
        />
      ),
      actionAssigned:
        disableBtn || resource?.status === "Un Assigned" ? null : engagementData
            ?.jobCloser?.status === "Not-Started" ||
          engagementData?.jobCloser?.status === "Not Started" ||
          engagementData?.jobCloser?.status === "Not Approved" ? (
          <ActionRender
            resource={resource}
            handleDeleteResource={handleDeleteResource}
            addResourceandSubDisabled={addResourceandSubDisabled}
          />
        ) : (
          <ActionAssigned
            resource={resource}
            handleEditResource={handleEditResource}
            isUpdating={isUpdating}
            addResourceandSubDisabled={addResourceandSubDisabled}
          />
        ),
    };
  });

  useEffect(()=>{
    const totalPCU = resourceData?.reduce((acc, curr) => {
      return acc + curr.totalPCU;
    }, 0);

    setBudjetChargeout(totalPCU)
  }, [resourceData, setBudjetChargeout])

  return (
    <ResourcePlanWrap>
      <Table
        bordered={false}
        dataSource={resourceData}
        columns={resourceColumns}
        rowKey="resource_id"
        pagination={false}
        loading={engagementData?.engagementId ? isLoading : false}
        onRow={(record, rowIndex) => {
          return {
            ref:
              rowIndex === filterContractUsers().length - 30
                ? lastElementRef
                : null,
            // onClick: () => {
            //   if (!disableBtn) {
            //     setSelectedRow(record);
            //     setShowEdit(true);
            //   }
            // },
          };
        }}
        footer={() => {
          if (resourceData?.length > 0) {
            const totalPCU = resourceData?.reduce((acc, curr) => {
              return acc + curr.totalPCU;
            }, 0);
            return (
              <p className="m-0 fw-bold">
                TOTAL PLANNED CHARGEOUT: {currencyAmount("$", totalPCU)}
              </p>
            );
          }
          return <p className="m-0 fw-bold">TOTAL PLANNED CHARGEOUT: 0</p>;
        }}
      />
      <ViewResourceModal
        show={viewResourceModal}
        onClose={() => setViewResourceModal(false)}
        // header={selectedRow?.engagementName}
        selectedResource={selectedRow}
        // bookedUserList={actualBookedUserList}
        // refetch={refetch}
        // engagementData={engagementData}
        generateSchedule={generateSchedule}
      />

      <EditResourceModal
        show={showEdit}
        onClose={() => setShowEdit(false)}
        header={selectedRow?.engagementName}
        selectedResource={selectedRow}
        bookedUserList={actualBookedUserList}
        refetch={() => {
          refetch();
          engagementRefetch();
        }}
        engagementData={engagementData}
        tempEngagementFormData={tempEngagementFormData}
      />
    </ResourcePlanWrap>
  );
};

export default ResourcePlan;

const ActionRender = ({ resource, handleDeleteResource, addResourceandSubDisabled }) => {
  return (
    <div>
      <Button
        className="delete-btn"
        type="primary"
        onClick={() => handleDeleteResource(resource)}
        disabled={addResourceandSubDisabled}
      >
        Delete
      </Button>
    </div>
  );
};

const ActionAssigned = ({
  resource,
  handleEditResource,
  isUpdating = false,
  addResourceandSubDisabled
}) => {
  return (
    <div>
      <Button
        className="delete-btn"
        type="primary"
        onClick={() => handleEditResource(resource)}
        disabled={addResourceandSubDisabled}
      >
        Un Assign
      </Button>
    </div>
  );
};

const ResourcePlanWrap = styled.div`
  .ant-table-footer {
    padding: 6px;
  }
  .delete-btn {
    border-radius: 5px;
  }
  .resources {
    font-weight: 700;
    .resource {
      cursor: pointer;

      &.active {
        text-decoration: underline;
      }

      &::after {
        content: "|";
        margin-left: 8px;
      }
    }
    .third-party-resource {
      margin-left: 8px;
      cursor: pointer;

      &.active {
        text-decoration: underline;
      }
    }
  }
`;
