import React from 'react'
import Declaration from './Declaration'
import { getUserInfo, getUserList } from 'redux/selectors';
import { connect } from 'react-redux';

function MyDeclaration({ userInfo, userList}) {

    const filteruser = userList?.find((item) => item?.userid === userInfo?.userid);
  return (
    <div>
        <Declaration filteruser={filteruser}/>
    </div>
  )
}
const mapStateToProps = (state) => ({
    userList: getUserList(state),
    userInfo: getUserInfo(state),
  });
  export default connect(mapStateToProps, null)(MyDeclaration);