import { Card, Col, Form, Row, Select, Table } from "antd";
import Button from "atoms/Button";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { useScrollFetch2 } from "hooks/useScrollFetch2";
import { sumBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getTempEngagementFormData,
  getUserInfo,
  getUserList,
} from "redux/selectors";
import UIModal from "reusableComponent/UIModal";
import styled from "styled-components";
import { currencyAmount } from "utils/functions";
import currencyMasterData from "common/currencyRateMasterData.json";

const labelStyle = {
  width: "150px",
  display: "inline-block",
  textAlign: "start",
  color: "#000000",
};

const JobCloserFields = ({ update, handleCloseEngagementViaModal }) => {
  const history = useHistory();
  const form = Form.useFormInstance();
  const userInfo = useSelector(getUserInfo);

  const [showBilling, setShowBilling] = React.useState(false);
  const [actualChargeout, setActualChargeout] = useState("");
  const [budjetChargeout, setBudjetChargeout] = useState("");
  const formValues = form.getFieldsValue(true);
  const tempEngagementFormData = useSelector((state) =>
    getTempEngagementFormData(state)
  );
  const { id } = useParams();

  const { getEngagementById, getBillingById, getTimeSheetByengagementId } =
    queryKeys;

  const { key, url } = getEngagementById(id);
  const { key: billingKey, url: billingUrl } = getBillingById(id);
  const { key: timesheetKey, url: timesheetUrl } =
    getTimeSheetByengagementId(id);
  const { data: engagementDataRes } = useFetch(key, url, {
    enabled: !!id,
  });
  const [profitLoss, setProfitLoss] = useState(0);
  const { data: billingList } = useFetch(billingKey, billingUrl, {
    enabled: !!id,
  });

  const { data: timeSheetList } = useFetch(timesheetKey, timesheetUrl, {
    enabled: !!id,
  });

  const billingData = useMemo(() => {
    return (
      billingList?.response?.items?.filter(
        (val) =>
          val?.statusname === "Approved by Partner" ||
          val?.statusname === "Approved by Finance"
      ) ?? []
    );
  }, [billingList]);

  const billingDisbursement = sumBy(billingData, (item) => {
    return sumBy(item.claims, (claim) => parseFloat(claim.amount));
  });

  const timeSheetData = useMemo(() => {
    return timeSheetList?.response?.response ?? [];
  }, [timeSheetList]);

  const engagementData = engagementDataRes?.data;

  useEffect(() => {
    const budjetChargeout = (
      engagementData?.employeeList ??
      tempEngagementFormData?.resourceList ??
      []
    )
      .filter((item) => {
        const resourceExtRate = parseFloat(item.resourceExtRate);
        return !isNaN(resourceExtRate) && item.personDay !== undefined;
      })
      ?.map((item) => parseFloat(item.resourceExtRate) * item.personDay * 8)
      .reduce((acc, value) => acc + value, 0);

    setBudjetChargeout(budjetChargeout);
  }, [engagementData]);

  const convertionRate = currencyMasterData.find(
    (currency) => currency.TBD_DESC === formValues?.currency
  )?.TBD_ABBR;

  const disbursementClaims = sumBy(
    timeSheetData,
    (item) =>
      (item?.claimsDisbursement || 0) * (parseFloat(convertionRate) || 1)
  );

  const actualBillingAmount = sumBy(
    billingData,
    (item) =>
      (parseFloat(item?.amount) || 0) * (parseFloat(convertionRate) || 1)
  );

  useEffect(() => {
    // Calculate the total billing amount
    const totalBilling = actualBillingAmount;
    // Calculate the total timeSheet cost
    const totalActualChargeoutAmount = sumBy(
      timeSheetData,
      (item) =>
        (parseFloat(item?.actualMandays) || 0) * (parseFloat(item?.rate) || 0)
    );

    // Calculate total claims
    const totalClaims = sumBy(
      timeSheetData,
      (item) => parseFloat(item?.claimsDisbursement) || 0
    );

    // Calculate the total subcontractor amount
    const totalSubcontractorAmount = sumBy(
      timeSheetData,
      (item) => parseFloat(item?.claimsSubcontract) || 0
    );

    // Calculate the total Bill Disbursement amount
    const totalBillDisbursementAmount = billingDisbursement;

    // Calculate the profit loss
    // Actual Calculation New flow
    // Billing amounts + Bill Disbsustment (New row which is coming from Billing Claims ) - Chargeout (Timehsset) - Claims (Both Subcont + dissbuontm
    const profitLoss =
      totalBilling +
      totalBillDisbursementAmount -
      totalActualChargeoutAmount -
      (totalClaims + totalSubcontractorAmount);

    setProfitLoss(profitLoss);
  }, [billingData, timeSheetData, engagementData, actualBillingAmount]);

  const budgetedProfit =
    parseInt(form.getFieldValue("usd_amount")) -
    (parseInt(budjetChargeout) +
      parseInt(
        sumBy(
          (
            engagementData?.subcontractor ??
            tempEngagementFormData?.subcontractList
          )?.map((item) => ({
            ...item,
            amount: parseInt(item.amount, 10) || 0, // Convert to integer, default to 0 if NaN
          })),
          "amount"
        )
      ));

  const createdBy = engagementData?.createdBy;

  const canAbleToClose =
    userInfo?.userid === createdBy ||
    userInfo?.userid === formValues?.partner ||
    userInfo?.userid === formValues?.person_incharge;

  return (
    <Card className="fields-card">
      <Row gutter={24}>
        <Col flex="auto" className="mb-2">
          <StyleTable
            bordered
            columns={[
              {
                title: "",
                dataIndex: "column1",
                key: "column1",
              },
              {
                title: "Budget(USD)",
                dataIndex: "budget",
                key: "budget",
              },
              {
                title: "Actual(USD)",
                dataIndex: "actual",
                key: "actual",
              },
            ]}
            dataSource={[
              {
                column1: "Billing Amount",
                budget:
                  currencyAmount("$", formValues?.usd_amount) ||
                  currencyAmount("$", 0),
                actual:
                  currencyAmount("$", actualBillingAmount) ??
                  currencyAmount("$", 0),
              },
              // {
              //   column1: "Billing Disbursement(Claims)",
              //   budget: currencyAmount("$", 0),
              //   actual:
              //     timeSheetData?.length > 0
              //       ? currencyAmount(
              //           "$",
              //           sumBy(timeSheetData, item => item?.claimsDisbursement || 0)
              //         )
              //       : currencyAmount("$", 0),
              // },

              {
                column1: "Billing Disbursement",
                budget: currencyAmount("$", 0),
                actual: currencyAmount("$", billingDisbursement),
              },

              {
                column1: "Chargeout",
                budget:
                  currencyAmount("$", budjetChargeout) ||
                  currencyAmount("$", 0),
                actual:
                  timeSheetData?.length > 0
                    ? currencyAmount(
                        "$",
                        sumBy(
                          timeSheetData,
                          (item) =>
                            parseFloat(item?.actualMandays || 0) *
                            parseFloat(item?.rate || 0)
                        )
                      )
                    : currencyAmount("$", 0),
              },

              {
                column1: "Disbursement(Claims)",
                budget: currencyAmount("$", 0),
                actual:
                  timeSheetData?.length > 0
                    ? currencyAmount("$", disbursementClaims)
                    : currencyAmount("$", 0),
              },

              // {
              //   column1: "Disbursement from Timesheet",
              //   budget: currencyAmount("$", 0),
              //   actual:
              //     timeSheetData?.length > 0
              //       ? currencyAmount(
              //           "$",
              //           sumBy(timeSheetData, item => item?.billDisbursement || 0)
              //         )
              //       : currencyAmount("$", 0),
              // },
              {
                column1: "Subcontract(Claims)",
                budget:
                  currencyAmount(
                    "$",
                    sumBy(
                      (
                        engagementData?.subcontractor ??
                        tempEngagementFormData?.subcontractList
                      )?.map((item) => ({
                        ...item,
                        amount: parseInt(item.amount, 10) || 0, // Convert to integer, default to 0 if NaN
                      })),
                      "amount"
                    )
                  ) ?? currencyAmount("$", 0),
                actual:
                  timeSheetData?.length > 0
                    ? currencyAmount(
                        "$",
                        sumBy(
                          timeSheetData,
                          (item) => item?.claimsSubcontract || 0
                        )
                      )
                    : currencyAmount("$", 0),
              },
              {
                column1: "Profit",
                budget: (
                  <span className={budgetedProfit > 0 ? "profit" : "loss"}>
                    {currencyAmount("$", budgetedProfit) ||
                      currencyAmount("$", 0)}
                  </span>
                ),
                actual: (
                  <span className={profitLoss > 0 ? "profit" : "loss"}>
                    {currencyAmount("$", profitLoss) || currencyAmount("$", 0)}
                  </span>
                ),
              },
              {
                column1: "PFA(Planned fee Adjustment) USD",
                budget:
                  budgetedProfit < 0
                    ? currencyAmount("$", Math.abs(budgetedProfit))
                    : currencyAmount("$", 0),
                actual: "",
              },
              {
                column1: "Profit - after PFA",
                budget:
                  budgetedProfit > 0
                    ? currencyAmount("$", budgetedProfit)
                    : currencyAmount("$", 0),
                actual: "",
              },
            ]}
            pagination={false}
            // scroll={{ y: 340 }}
          />
        </Col>
        <Col flex="auto">
          {/* Status */}
          <Form.Item
            label={<span style={labelStyle}>Status</span>}
            name="status"
          >
            <Select
              disabled={formValues?.status !== "Active"}
              placeholder="Select"
              onChange={(value) => {
                if (value === "Closed") {
                  handleCloseEngagementViaModal();
                }
              }}
              options={[
                // { label: "Not Started", value: "Not-Started" },
                { label: "Active", value: "Active" },
                // { label: "Not Approved", value: "Not Approved" },
                {
                  label: "Closed",
                  value: "Closed",
                  disabled: canAbleToClose
                    ? false
                    : formValues?.sent_to_referaal
                    ? false
                    : true, // Disable if sent_to_referral is false
                },
              ].filter(Boolean)}
            />
          </Form.Item>

          {update && (
            <div className="job-closer-buttons-container">
              <Row
                gutter={[8, 8]}
                style={{
                  marginTop: "1rem",
                }}
              >
                <Col span={12}>
                  <Button
                    className="custom-engagement-btn"
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      history.push(`/engagement/${id}/financialsummary`);
                    }}
                  >
                    Financial Summary
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    className="custom-engagement-btn"
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      history.push(`/engagement/${id}/billing`);
                    }}
                    disabled={
                      // engagementData?.jobCloser?.status === "Not Started" ||
                      engagementData?.jobCloser?.status === "Not Approved"
                    }
                  >
                    Billing
                  </Button>
                </Col>
                {/* <Col span={12}>
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      setShowBilling(true);
                    }}
                  >
                    Billing
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                  >
                    Financial Summary
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                  >
                    Claims
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      history.push("/engagement/1/client-survey");
                    }}
                  >
                    Client Survey
                  </Button>
                </Col> */}
              </Row>
            </div>
          )}
        </Col>
      </Row>

      <UIModal
        open={showBilling}
        onClose={() => setShowBilling(false)}
        header={"Billing"}
      >
        {/* //TODO: Implement Billing UI */}
      </UIModal>
    </Card>
  );
};

export default JobCloserFields;

const StyleTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #dbdbdb;
    font-weight: 700;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table {
    border-top: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid #dbdbdb;
  }
  .ant-table-cell {
    padding: 7px 12px;
    font-size: 16px;
    font-weight: 500;
  }
  .ant-table-container {
    border-radius: 5px;
  }
  .ant-table {
    border-radius: 5px;
  }
`;
