import TimeSheet from "molecules/TimeSheet";
import React, { useEffect } from "react";
import LeftIcon from "assets/icons/UI/left.svg";
import RightIcon from "assets/icons/UI/right.svg";
import Button from "atoms/Button";
import { DatePicker, Form, Spin, notification } from "antd";
import { useFetch } from "hooks/useFetch";
import queryKeys from "common/queryKey";
import { ClickAwayListener } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCachedTimesheets, getUserInfo, getUserList } from "redux/selectors";
import { useMutate } from "hooks/useMutate";
import { useUpdate } from "hooks/useUpdate";
import { useQueryClient } from "react-query";
import useWeekData, {
  generateTimesheetWeek,
  periodToDate,
} from "./useWeekData";
import { ConfigProvider } from "antd";
import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import "moment/locale/en-gb";
import { apiInstance } from "config/baseUrl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TimeSheetOverView from "./TimeSheetOverView";
import TimeSheetFooterHint from "./TimeSheetFooterHint";
import { ONGOING, PENDING, WITHDRAWN, DRAFT } from ".";
import useTimesheetfns from "./useTimesheetfns";
import { toggleProcessingModal } from "redux/actions";

moment.locale("en-gb");

const MyTimeSheet = ({ selectedPeriod, duplicateTimesheet = false }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedPeriodToDate = periodToDate(selectedPeriod);

  const [copyWeekTimesheet, setCopyWeekTimesheet] = React.useState(null);
  const [isCopying, setIsCopying] = React.useState(false);

  const {
    date,
    week,
    currentWeek,
    edit,
    setEdit,
    currentNiceWeekRange,
    disabledDate,
    handleNextWeek,
    handlePreviousWeek,
    onChange,
    setPersonInCharge,
    personIncharge,
  } = useWeekData(selectedPeriodToDate);

  const { getTimesheet, postTimesheet, updateTimesheet } = queryKeys;

  const userList = useSelector(getUserList);

  const userInfo = useSelector((state) => getUserInfo(state));

  const cachedTimesheets = useSelector(getCachedTimesheets);

  const cachedTimeSheet = cachedTimesheets?.[week] || []; // get cached timesheet for the week

  const currentUser = userList.find((user) => user.userid === userInfo.userid);

  const OfficeSuperVisorId = currentUser?.OfficeSupervisorId || null;

  const OfficeSuperVisorUserId = userList.find(
    (user) => user.employeeid === OfficeSuperVisorId
  )?.userid;

  const chargeOutRate = currentUser?.chargeoutrate
    ? parseInt(currentUser?.chargeoutrate)
    : 0;

  const {
    data: timesheetDataRes,
    isLoading,
    isFetching,
    isFetched
  } = useFetch(
    `${getTimesheet.key}-week:${week}`,
    `${getTimesheet.url}?week=${week}&employeeId=${userInfo.employer_id}`,
    {
      enabled: !!week,
    }
  );

  const timeSheetStatus =
    timesheetDataRes?.response?.response?.status || ONGOING;

  const updateableTimesheet =
    timeSheetStatus === WITHDRAWN || timeSheetStatus === DRAFT;

  const [form] = Form.useForm();

  const { mutate: submitTimesheet, isLoading: isSubmitting } = useMutate(
    postTimesheet.key,
    postTimesheet.url
  );

  const { mutate: update, isLoading: isUpdating } = useUpdate(
    updateTimesheet.key,
    updateTimesheet.url
  );

  const onSubmit = async (values, draft = false) => {
    const timeSheetData = generateTimeSheetData(values, draft);

    if (!draft && !isChargeAbleAmtValid(timeSheetData)) {
      return false;
    }

    const data = {
      week: week,
      employeeId: userInfo.userid,
      employeeName: userInfo.name,
      departementId: userInfo.department,
      rate: chargeOutRate,
      timeSheet: timeSheetData,
      status: draft ? DRAFT : PENDING,
    };
    // return false;
    if (updateableTimesheet) {
      const updatedData = {
        ...timesheetDataRes?.response?.response,
        status: draft ? DRAFT : PENDING,
        timeSheet: data.timeSheet,
      };
      update(updatedData, {
        onSuccess: (res) => {
          queryClient.invalidateQueries(`${getTimesheet.key}-week:${week}`);
          queryClient.invalidateQueries(
            `${getTimesheet.key}-week:${week}-status:${
              draft ? "draft" : "pending"
            }`
          );
          notification.success({
            message: `Timesheet Updated Successfully`,
          });
        },
      });
    } else {
      submitTimesheet(data, {
        onSuccess: (res) => {
          console.log("onSubmit  res-->", res);
          queryClient.invalidateQueries(
            `${getTimesheet.key}-week:${week}-status:${
              draft ? "draft" : "pending"
            }`
          );
          queryClient.invalidateQueries(`${getTimesheet.key}-week:${week}`);
          notification.success({
            message: `Timesheet Submitted Successfully`,
          });
        },
      });
    }
  };

  useEffect(() => {
    dispatch(toggleProcessingModal(isUpdating || isSubmitting));
  }, [isUpdating, isSubmitting]);

  const {
    timeSheet,
    setTimeSheet,
    generateTimeSheetData,
    handleAddMore,
    handleAddMoreClaim,
    handleClearClaim,
    handleResetClaim,
    handleRemoveClaim,
    handlePersonInCharge,
    handleClearTimesheet,
    handleSaveTimesheet,
    isChargeAbleAmtValid,
    handleSubmitTimesheet,
  } = useTimesheetfns({
    personIncharge,
    OfficeSuperVisorUserId,
    currentWeek,
    form,
    setPersonInCharge,
    week,
    timesheetDataRes,
    onSubmit,
    isFetched,
    duplicateTimesheet
  });

  const editableTimesheet =
    timeSheetStatus === WITHDRAWN ||
    timeSheetStatus === ONGOING ||
    timeSheetStatus === DRAFT;

  useEffect(() => {
    if (
      cachedTimeSheet.length > 0 &&
      !timesheetDataRes?.response?.response?.timeSheet
    ) {
      setTimeSheet(cachedTimeSheet);
      form.setFieldsValue(cachedTimeSheet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedTimeSheet, timesheetDataRes]);

  const pasteTimeSheet = async (week, date) => {
    setIsCopying(true);
    const accessToken = window.localStorage.getItem("token");

    try {
      const response = await queryClient.fetchQuery({
        queryKey: `${getTimesheet.key}-week:${week}`,
        queryFn: async () => {
          return await apiInstance({
            method: "GET",
            url: `${getTimesheet.url}?week=${week}&employeeId=${userInfo.employer_id}`,
            headers: {
              Tymeplusjwt: accessToken,
            },
          })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
        },
      });

      const timeSheetResponse =
        response?.response?.response?.timeSheet || false;

      if (timeSheetResponse) {
        // onChange(date);

        const statusRemovedTimesheet = timeSheetResponse.map((value) => {
          return {
            ...value,
            status: "",
            approval: {},
            declined: {},
          };
        });

        setTimeSheet(statusRemovedTimesheet);
        form.setFieldsValue(statusRemovedTimesheet);
      } else {
        notification.error({
          // message: `Timesheet for week ${week} not found`,
          message: `Timesheet Not Found`,
        });
      }
      setCopyWeekTimesheet(null);
      setIsCopying(false);
    } catch (error) {
      setIsCopying(false);
      console.log("error", error);
    }
  };

  const handlePasteTimesheet = (date) => {
    var weekStart = date.clone().startOf("isoWeek");
    var weekEnd = date.clone().endOf("isoWeek");

    setCopyWeekTimesheet(date);
    pasteTimeSheet(generateTimesheetWeek(weekStart, weekEnd), weekStart);
  };

  return (
    <Form
      form={form}
      initialValues={timeSheet}
      layout="vertical"
      onFinishFailed={() => {
        notification.error({
          message: "Please fill all the required fields",
        });
      }}
    >
      <div className="timesheet-header">
        <div>
          <img
            src={LeftIcon}
            alt="left"
            className="left-icon"
            onClick={handlePreviousWeek}
          />
          <img
            src={RightIcon}
            alt="right"
            className="right-icon"
            onClick={handleNextWeek}
          />
          <span className="d-inline-flex justify-content-center align-items-center gap-2">
            <span className="date">Date:</span>{" "}
            {edit ? (
              <ClickAwayListener onClickAway={() => setEdit(false)}>
                <div className="position-relative">
                  <ConfigProvider locale={en_GB}>
                    <DatePicker
                      onChange={onChange}
                      picker="week"
                      value={date}
                      open={true}
                      disabledDate={disabledDate}
                    />
                  </ConfigProvider>
                </div>
              </ClickAwayListener>
            ) : (
              <div className="current-week me-2" onClick={() => setEdit(true)}>
                {currentNiceWeekRange}
              </div>
            )}
          </span>
        </div>

        <div className="d-inline-flex justify-content-center align-items-center gap-2 flex-wrap">
          {/* <Button
            type="primary"
            className="history-button"
            onClick={() => history.push("/timesheet-history")}
          >
            History
          </Button> */}
        </div>
      </div>
      <Spin spinning={isLoading || isCopying}>
        <div className="mb-4">
          <TimeSheetOverView timeSheet={timeSheet} />
        </div>

        {/* {timeSheetStatus === ONGOING ? (
          <div className="d-inline-flex justify-content-center align-items-center gap-2 mb-2">
            <span className="duplicate-week">Duplicate Week for Cloning: </span>
            <span>
              <div className="position-relative">
                <ConfigProvider locale={en_GB}>
                  <DatePicker
                    picker="week"
                    value={copyWeekTimesheet}
                    onChange={handlePasteTimesheet}
                    disabledDate={disabledDate}
                    placeholder="Select"
                  />
                </ConfigProvider>
              </div>
            </span>
          </div>
        ) : null} */}

        <TimeSheet
          editable={editableTimesheet}
          data={timeSheet}
          extraActions={{
            handlePersonInCharge,
            handleClearTimesheet,
            handleSaveTimesheet,
            handleAddMoreClaim,
            handleClearClaim,
            handleResetClaim,
            handleRemoveClaim,
          }}
        />

        {editableTimesheet && (
          <div className="d-flex justify-content-start align-items-center gap-2 mt-4">
            <Button type="primary" className="add-more" onClick={handleAddMore}>
              Add More
            </Button>
          </div>
        )}
      </Spin>

      <TimeSheetFooterHint />

      <div className="d-flex justify-content-start align-items-center gap-2 mt-4">
        {editableTimesheet && !isLoading && !isFetching && !isCopying && (
          <Button
            type="primary"
            onClick={handleSubmitTimesheet}
            loading={isSubmitting || isUpdating}
            className="submit-button"
          >
            Submit
          </Button>
        )}
        {/* <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting || isUpdating}
            className="submit-button"
          >
            Submit
          </Button> */}
        {/* <Button
          type="primary"
          className="close-button"
          onClick={() => {
            history.push("/timesheet");
          }}
        >
          Close
        </Button> */}
      </div>
    </Form>
  );
};

export default MyTimeSheet;
