import { sumBy } from "lodash";
import { initialClaim, InitialTimeSheetDataFn } from "./InitialTimeSheetData";
import { DRAFT, MAX_WORKING_HOURS, MIN_WORKING_HOURS, PENDING } from ".";
import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { setCacheTimesheet } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { getClientid, getUserInfo, getUserRoleId } from "redux/selectors";
import moment from "moment";
import { CALL_API } from "common/API";

const useTimesheetfns = ({
  personIncharge,
  OfficeSuperVisorUserId,
  currentWeek,
  form,
  setPersonInCharge,
  week,
  timesheetDataRes,
  onSubmit,
  isFetched,
  duplicateTimesheet,
}) => {
  const dispatch = useDispatch();

  const clientId = useSelector(getClientid);
  const userRoleId = useSelector(getUserRoleId);
  const userInfo = useSelector(getUserInfo);

  const monday = moment(currentWeek, "YYYY-MM-DD");
  const sunday = moment(currentWeek, "YYYY-MM-DD").add(6, "days");

  const { key: holidayKey, url: holdiayUrl } =
    queryKeys.getHolidayList(clientId);

  const { data: holidayDataRes } = useFetch(
    holidayKey,
    holdiayUrl,
    {
      enabled: !!clientId,
    },
    {
      start_date: monday,
      end_date: sunday,
    }
  );

  const holidays = holidayDataRes?.response;

  const hasHolidays = holidays?.length > 0;

  const initialTimeSheet = InitialTimeSheetDataFn(currentWeek, hasHolidays);

  const [timeSheet, setTimeSheet] = useState(initialTimeSheet);

  const setInitialTimesheet = async () => {
    // setTimeSheet(initialTimeSheet);
    // form.setFieldsValue(initialTimeSheet);

    const [holidaysRes, leaveRes] = await Promise.all([
      CALL_API(`holiday-list/${clientId}`, "post", {
        start_date: monday,
        end_date: sunday,
      }),
      CALL_API(`leave-list/${clientId}`, "post", {
        status_id: "",
        officesupervisorid: [""],
        selected_role_id: userRoleId,
        start_date: monday,
        end_date: sunday,
      }),
    ]);

    const myLeaves = leaveRes.leaves.filter(
      (leave) => leave.userid === userInfo.userid && (leave?.status_id === "5" && leave?.having_cancel_request === "0")
    );

    const hasTakenLeave = myLeaves.length > 0;

    const hasHolidays = holidaysRes?.length > 0;

    const initialTimeSheetWithHolidays = InitialTimeSheetDataFn(
      currentWeek,
      hasHolidays || hasTakenLeave
    );

    setTimeSheet(initialTimeSheetWithHolidays);
    form.setFieldsValue(initialTimeSheetWithHolidays);
  };

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    if (timesheetDataRes?.response?.response?.timeSheet) {
      // TimeSheet Data Exists
      setTimeSheet(timesheetDataRes?.response?.response?.timeSheet);
      form.setFieldsValue(timesheetDataRes?.response?.response?.timeSheet);
    } else if (duplicateTimesheet) {
      // Duplicate TimeSheet
      setTimeSheet(duplicateTimesheet);
      form.setFieldsValue(duplicateTimesheet);
    } else {
      // TimeSheet Data Doesn't Exist
      setInitialTimesheet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheetDataRes, form]);

  const generateTimeSheetData = (values, draft = false) => {
    return Object.values(values).map((value, index) => {
      const personInCharge =
        personIncharge?.[index] || OfficeSuperVisorUserId || "";

      return {
        ...value,
        mon: parseFloat(value?.mon) || 0,
        tue: parseFloat(value?.tue) || 0,
        wed: parseFloat(value?.wed) || 0,
        thu: parseFloat(value?.thu) || 0,
        fri: parseFloat(value?.fri) || 0,
        sat: parseFloat(value?.sat) || 0,
        sun: parseFloat(value?.sun) || 0,
        sum: parseFloat(value?.sum) || 0,
        claims: value?.claims || [],
        personInCharge: personInCharge,
        currentWeek: currentWeek.format("YYYY-MM-DD"),
        ncClient: value?.ncClient || null,
        subEngagement: value?.subEngagement || null,
        remarks: value?.remarks || "",
        status: draft ? DRAFT : PENDING,
        approval: {},
        declined: {},
      };
    });
  };

  const isChargeAbleAmtValid = (timeSheet) => {
    const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const validateDayTotal = (total, dayName) => {
      if (total < MIN_WORKING_HOURS || total > MAX_WORKING_HOURS) {
        notification.error({
          message: `${dayName} total hours should be between 8 and 12`,
        });
        return false;
      }
      return true;
    };

    for (let i = 0; i < daysOfWeek.length; i++) {
      const dayTotal = sumBy(timeSheet, daysOfWeek[i]);
      if (daysOfWeek[i] === "sat" || daysOfWeek[i] === "sun") {
        if (dayTotal !== 0 && !validateDayTotal(dayTotal, dayNames[i])) {
          return false;
        }
      } else {
        if (!validateDayTotal(dayTotal, dayNames[i])) {
          return false;
        }
      }
    }

    return true;
  };

  const handleAddMore = () => {
    if (timeSheet.length >= 20) {
      notification.error({
        message: "You can't add more than 20 Slots",
      });
      return;
    }

    const values = form.getFieldsValue(true);
    const actualTimeSheet = generateTimeSheetData(values);
    let data = [...actualTimeSheet];
    data.push(...InitialTimeSheetDataFn(currentWeek));
    setTimeSheet(data);
    form.setFieldsValue(data);
  };

  const handleAddMoreClaim = (timeSheetIndex) => {
    const values = form.getFieldsValue(true);

    const claimsLength = values[timeSheetIndex]?.claims?.length || 0;

    if (claimsLength >= 5) {
      notification.error({
        message: "You can't add more than 5 claims",
      });
      return;
    }

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        value.claims.push(initialClaim);
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handleClearClaim = (timeSheetIndex, claimIndex) => {
    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        return {
          ...value,
          claims: value.claims
            .map((claim, i) => {
              if (i === claimIndex) {
                return claimIndex === 0 ? initialClaim : null;
              }
              return claim;
            })
            .filter((claim) => claim !== null),
        };
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handleResetClaim = (timeSheetIndex) => {
    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        return {
          ...value,
          claims: [initialClaim],
        };
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handleRemoveClaim = (timeSheetIndex) => {
    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        return {
          ...value,
          claims: [],
        };
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handlePersonInCharge = useCallback((index, value) => {
    setPersonInCharge((prev) => {
      const data = [...prev];
      data[index] = value;
      return data;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearTimesheet = (index) => {
    if (index === 0) {
      setTimeSheet(initialTimeSheet);
      form.setFieldsValue(initialTimeSheet);
      notification.info({
        message: "Timesheet Section Cleared",
      });
      return;
    }

    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    if (index >= 0 && index < data.length) {
      data = data.filter((_, i) => i !== index);
      setTimeSheet(data);
      form.setFieldsValue(data);
      notification.info({
        message: "Timesheet Section Removed",
      });
    } else {
      console.error("Invalid index:", index);
    }
  };

  const handleSaveTimesheet = () => {
    const values = form.getFieldsValue(true);

    const timeSheetData = generateTimeSheetData(values, true);

    dispatch(setCacheTimesheet(week, timeSheetData));
    onSubmit(values, true);
    // notification.success({
    //   message: "Timesheet Saved",
    // });
  };

  const handleSubmitTimesheet = () => {
    const values = form.getFieldsValue(true);

    onSubmit(values);
  };

  return {
    timeSheet,
    setTimeSheet,
    initialTimeSheet,
    generateTimeSheetData,
    handleAddMore,
    handleAddMoreClaim,
    handleClearClaim,
    handleResetClaim,
    handleRemoveClaim,
    handlePersonInCharge,
    handleClearTimesheet,
    handleSaveTimesheet,
    isChargeAbleAmtValid,
    handleSubmitTimesheet,
  };
};

export default useTimesheetfns;
