import React, { useContext } from "react";
import moment from "moment";
import { findWeekIdsInRange } from "utils/Date";
import { connect, useSelector } from "react-redux";
import {
  getMenuList,
  getUserInfo,
  getUserList,
  getUserRoleId,
} from "redux/selectors";
import TabsList from "molecules/TabsList";
import MyEngagement from "./MyEngagement";
import { CalendarContext } from ".";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { getAllSubordinates } from "common/Constants";
import AllEngagements from "./AllEngagements";
import AddResourceFromCalender from "./AddResourceFromCalender";
import EngagementWeekCalendar from "pages/WeekCalendarView/EngagementWeekCalendar";

const getListOfWeeksIn = (miniCalendarCurrentDate) => {
  const firstDate = moment(miniCalendarCurrentDate).startOf("month");
  const lastDate = moment(miniCalendarCurrentDate).endOf("month");

  const weeks = findWeekIdsInRange([firstDate, lastDate]);

  return { firstDate, lastDate, weeks };
};

function EngagementCalendar({ userInfo, userList, tabId, setTabId }) {
  const { tabBarExtraContent, miniCalendarCurrentDate } =
    useContext(CalendarContext);

  const [showAddResource, setShowAddResource] = React.useState(false);
  const [clickedDate, setClickedDate] = React.useState(null);

  const menuList = useSelector(getMenuList);
  const userRoleId = useSelector(getUserRoleId);

  let Menulist = menuList[userRoleId] || Object.values(menuList)[0] || menuList;

  Menulist = Array.isArray(Menulist) ? Menulist : [];

  const handleShowAddResource = (clickedDate) => {
    setShowAddResource(true);
    setClickedDate(clickedDate);
  };

  const handleClose = () => {
    setShowAddResource(false);
  };

  const {
    weeks: weeksThisMonth,
    firstDate,
    lastDate,
  } = getListOfWeeksIn(miniCalendarCurrentDate);

  const currentUser = userList?.find(
    (item) => item?.userid === userInfo.userid
  );

  const subordinates = getAllSubordinates(userList, currentUser?.employeeid);

  const { key, url } = queryKeys.getResourePlanningList(
    weeksThisMonth.join(",")
  );

  const {
    data: resourceDataRes,
    isLoading: isResourceLoading,
    refetch,
  } = useFetch(
    key,
    url,
    {
      enabled: weeksThisMonth.length > 0,
    },
    {
      startDate: new Date(firstDate),
      endDate: new Date(lastDate),
      employeeIds: [],
      engagementIds: [],
      weekId: weeksThisMonth,
      managingOffice: currentUser?.managingoffice?.[0]
    }
  );

  const myResourceData = resourceDataRes?.response?.response?.filter((res) => {
    return res.userId === userInfo.userid;
  });

  const myTeamResourceData = resourceDataRes?.response?.response?.filter(
    (res) => {
      return subordinates.some((subordinate) => subordinate === res.employeeId);
    }
  );

  const allResourceData = resourceDataRes?.response?.response;

  const myBookedDates = myResourceData?.reduce((acc, res) => {
    const startDate = moment(res.actualBookedStartDate, "YYYY-MM-DD");
    const endDate = moment(res.actualBookedEndDate, "YYYY-MM-DD");

    const dates = [];
    let current = moment(startDate);
    while (current.isBefore(endDate) || current.isSame(endDate, "day")) {
      dates.push({
        date: current.format("YYYY-MM-DD"),
        ...res,
      });
      current.add(1, "day");
    }
    return [...acc, ...dates];
  }, []);

  const { getAllocatedResourceList } = queryKeys;

  const selectedDateRange = [moment(firstDate), moment(lastDate)];

  const weekList = findWeekIdsInRange(selectedDateRange);

  const { key: getAllocatatedResourceKey, url: getAllocatatedResourceUrl } =
    getAllocatedResourceList(weekList?.join(", "));

  const { data: resourceAllocatedData } = useFetch(
    getAllocatatedResourceKey,
    getAllocatatedResourceUrl,
    {
      enabled: weekList?.length > 0,
    },
    {
      engagementId: "",
      weekLists: weekList,
    }
  );

  const bookedUserList = resourceAllocatedData?.response?.response?.flat();

  const hasMyEngagementAccess = Menulist?.some(
    (item) => item.key === "myengagementcalendar" && item.permission.view
  );

  const hasMyTeamEngagementAccess = Menulist?.some(
    (item) => item.key === "teamengagementcalendar" && item.permission.view
  );

  const hasAllEngagementAccess = Menulist?.some(
    (item) => item.key === "allengagementcalendar" && item.permission.view
  );

  const items = [
    hasMyEngagementAccess && {
      id: "Mine",
      label: "Mine",
      component: (
        <EngagementWeekCalendar
          myBookedDates={myBookedDates}
          isResourceLoading={isResourceLoading}
          weeksThisMonth={weeksThisMonth}
        />
      ),
    },
    hasMyTeamEngagementAccess && {
      id: "My Team",
      label: "My Team",
      component: (
        <AllEngagements
          isResourceLoading={isResourceLoading}
          resources={myTeamResourceData}
          handleShowAddResource={handleShowAddResource}
        />
      ),
    },
    hasAllEngagementAccess && {
      id: "All",
      label: "All",
      component: (
        <AllEngagements
          isResourceLoading={isResourceLoading}
          resources={allResourceData}
          handleShowAddResource={handleShowAddResource}
        />
      ),
    },
  ];

  const handleTabSelect = (key) => {
    setTabId(key);
  };

  return (
    <>
      <TabsList
        items={items}
        tabBarExtraContent={tabBarExtraContent}
        tabId={tabId}
        handleTabSelect={handleTabSelect}
      />
      <AddResourceFromCalender
        show={showAddResource}
        onClose={handleClose}
        selectedDateRange={selectedDateRange}
        bookedUserList={bookedUserList}
        header="Add Resource"
        clickedDate={clickedDate}
        refetch={refetch}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(EngagementCalendar);
