import Button from "atoms/Button";
import Title from "atoms/Title";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import AddNewBilling from "./AddNewBilling";
import moment from "moment";
import Table from "atoms/Table";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import queryKeys from "common/queryKey";
import { useScrollFetch2 } from "hooks/useScrollFetch2";
import download from "downloadjs";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useFetch } from "hooks/useFetch";
import { currencyAmount } from "utils/functions";
import { ViewClaims } from "./ViewClaims";
import { STATUS_CODE } from "common/Constants";
import { CALL_API } from "common/API";
import Icon from "atoms/Icon";
import { Modal } from "antd";
import BillingInvoice from "./BillingInvoice";
import currencyMasterData from "common/currencyRateMasterData.json";

const Billing = () => {
  const [loadingId, setLoadingId] = useState(null);
  const [claimsData, setClaimsData] = useState([]);
  const [showBillingInvoiceModal, setShowBillingInvoiceModal] = useState(false);
  const [billingInvoiceData, setBillingInvoiceData] = useState(null);

  const extractFilename = (url) => {
    const parsedUrl = new URL(url);
    const fileName = parsedUrl.pathname.split("/").pop();
    return fileName;
  };

  const showBillingInvoice = (record) => {
    setShowBillingInvoiceModal(true);
    setBillingInvoiceData(record);
  };

  const handleCloseInvoiceModal = () => {
    setShowBillingInvoiceModal(false);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "billingdate",
      key: "billingdate",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // className: "normal-column",
    },
    // {
    //   title: "Sub Engagement",
    //   dataIndex: "subEngagement",
    //   key: "subEngagement",
    //   // className: "normal-column",
    // },
    {
      title: "Curreny",
      dataIndex: "currency",
      key: "currency",
      // className: "normal-column",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      // className: "normal-column",
    },
    {
      title: "Type",
      dataIndex: "internalbilling",
      key: "type",
      render: (text, record) =>
        record.internalbilling ? "Internal Billing" : "External Billing",
      // className: "normal-column",
    },
    {
      title: "VAT/NON-VAT",
      dataIndex: "vat",
      key: "vat",
    },
    {
      title: "Financial Year",
      dataIndex: "financialyear",
      key: "financialyear",
    },
    {
      title: "LOE Ref No",
      dataIndex: "loerefno",
      key: "loerefno",
    },
    {
      title: "Contact Person",
      dataIndex: "contactperson",
      key: "contactperson",
    },

    {
      title: "Contact Person A/C",
      dataIndex: "contactpersonac",
      key: "contactpersonac",
      // className: "normal-column",
    },
    // {
    //   title: "File Name",
    //   dataIndex: "billingfile",
    //   key: "filename",
    //   render: (text) => extractFilename(text),
    // },
    {
      title: "Status",
      dataIndex: "statusname",
      key: "statusname",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          {record.statusname === "Pending" && (
            <Button
              type="primary"
              onClick={(event) => {
                event.stopPropagation();
                history.push(`/engagement/${id}/addbills`, { record });
              }}
              style={{ padding: "8px 8px", fontSize: "12px" }}
            >
              <Icon name="edit" />
            </Button>
          )}
          {/* <Button
        type="primary"
        onClick={() => deleteBillings(record)}
        loading={loadingId === record.billingid}
        style={{ padding: '8px 8px', fontSize: '12px' }}
       >
          <Icon name="trash"/>
       </Button>  */}
          <Icon
            name="eye"
            className="mx-2"
            style={{ fontSize: "24px", cursor: "pointer" }}
            onClick={(event) => {
              // history.push(`/engagement/${id}/addbills`, {
              //   record,
              //   viewbillings: true,
              // });
              event.stopPropagation();
              showBillingInvoice(record);
            }}
          />
        </>
      ),
    },
    {
      title: "Claims",
      dataIndex: "claims",
      key: "claims",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={(event) => {
            event.stopPropagation();
            handleClaims();
            setClaimsData(record?.claims);
          }}
        >
          View
        </Button>
      ),
    },
    // {
    //   title: "Attachment",
    //   dataIndex: "billingfile",
    //   key: "billingfile",
    //   render: (text, record) => (
    //     <Button type="primary" loading={false} onClick={() => download(record?.billingfile)}>
    //       Download
    //     </Button>
    //   ),
    // },
  ];

  const handleClaims = () => {
    setIsModalVisible(true);
  };

  const { id } = useParams();
  const history = useHistory();
  const cursorStyle = { cursor: "pointer" };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const queryParams = new URLSearchParams();
  queryParams.append("engagementid", id);

  const queryString = queryParams.toString();

  const { getAllBillList, getEngagementById, getClaims } = queryKeys;
  const { key: engagementKey, url: engagementUrl } = getEngagementById(id);

  const { data: engagementDataRes } = useFetch(engagementKey, engagementUrl, {
    enabled: !!id,
  });

  const engagementData = engagementDataRes?.data;

  const { key, url } = getAllBillList(id, `?${queryString}`);

  const { data, lastElementRef, isLoading, refetch } = useScrollFetch2(
    key,
    {
      endPoint: url,
      queryParam: "lastEvaluatedKey",
    },
    {
      enabled: !!id,
    }
  );

  const getCurrencyCode = (currency) => {
    return currencyMasterData?.find((item) => item.TBD_DESC === currency)?.TBD_ABBR || "";
  }

  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.response?.items === undefined) return acc;
        return [
          ...acc,
          ...page?.response?.items.map((item) => {
            return {
              ...item,
              amount:
                item?.currency === "USD" || item?.currency === "US Dollar"
                  ? `$${item?.amount}`
                  : `${item?.amount}`,
            };
          }),
        ];
      }, []);
    } else {
      return [];
    }
  }, [data]);

  const deleteBillings = async (record) => {
    setLoadingId(record.billingid);
    const { code } = await CALL_API(
      `insyts/billing?engagementid=${record?.engagementid}&billingid=${record?.billingid}`,
      "delete"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setLoadingId(null);
      refetch();
    }
  };

  // const dataList = useMemo(() => {
  //   if (data) {
  //     console.log("data -->", data);
  //     console.log("data.pages -->", data.pages);
  //     // Ensure data.pages is an array
  //     if (!Array.isArray(data.pages)) {
  //       console.error("data.pages is not an array:", data.pages);
  //       return [];
  //     }
  //     return data.pages.reduce((acc, page) => {
  //       console.log("page--->", page);
  //       // Ensure page.response.items is an array
  //       if (!Array.isArray(page.response.items)) {
  //         console.error("page.response.items is not an array:", page.response.items);
  //         return acc;
  //       }

  //       return [...acc, ...page.response.items];
  //     }, []);
  //   } else {
  //     return [];
  //   }
  // }, [data]);

  // console.log("dataList --->", dataList)

  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      {engagementData?.information?.jobTitle}
    </label>,
    <Typography key="3" color="text.primary">
      Billing
    </Typography>,
  ];

  const ApprovedTotalBillingAmount = dataList?.reduce(
    (acc, item) =>(item?.internalbilling && (item?.statusname=== "Approved by Partner" || item?.statusname=== "Approved by Finance")) ? acc + (parseInt(item?.amount?.replace(/[$,]/g, ''), 10) || 0) : acc,
    0
  );
  const ApprovedTotalExternalBillingAmount = dataList?.reduce(
    (acc, item) =>(!item?.internalbilling && (item?.statusname=== "Approved by Partner" || item?.statusname=== "Approved by Finance")) ? acc + (parseInt(item?.amount?.replace(/[$,]/g, ''), 10) || 0) : acc,
    0
  );

  const PendingTotalBillingAmount = dataList?.reduce(
    (acc, item) =>(item?.internalbilling && item?.statusname=== "Pending") ? acc + (parseInt(item?.amount?.replace(/[$,]/g, ''), 10) || 0) : acc,
    0
  );

  const PendingTotalExternalBillingAmount = dataList?.reduce(
    (acc, item) =>(!item?.internalbilling && item?.statusname=== "Pending") ? acc + (parseInt(item?.amount?.replace(/[$,]/g, ''), 10) || 0) : acc,
    0
  );



  return (
    <>
      <BillingWrap>
        <div className="title mt-3">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>

          <Button
            type="primary"
            onClick={() => {
              history.push(`/engagement/${id}/addbills`);
            }}
          >
            Add
          </Button>
        </div>
        
        <Table
          bordered={false}
          className="mb-3"
          loading={isLoading}
          dataSource={dataList}
          columns={columns}
          rowKey=""
          pagination={false}
          onRow={(record, index) => ({
            ref: index === dataList.length - 30 ? lastElementRef : null,
            onClick: (event) => {
              event.stopPropagation();
              showBillingInvoice(record);
            },
          })}
        />
        {dataList?.length > 0 &&(
          <div className="w-100 d-flex justify-content-end mt-5">
          <div>
          <h6 >Approved (Internal) Total Billing amount: {ApprovedTotalBillingAmount}</h6>  
            <h6 >Approved (External) Total Billing amount: {ApprovedTotalExternalBillingAmount}</h6>
            <h6 >Pending (Internal) Total Billing amount: {PendingTotalBillingAmount}</h6>  
            <h6 >Pending (External) Total Billing amount: {PendingTotalExternalBillingAmount}</h6>
          </div>
            
        </div>
        )}
        
      </BillingWrap>

      <BillingInvoice
        billingInvoiceData={billingInvoiceData}
        open={showBillingInvoiceModal}
        handleClose={handleCloseInvoiceModal}
      />

      <ViewClaims
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        claimsDataSource={claimsData}
        isViewClims={true}
      />
    </>
  );
};

export default Billing;

const BillingWrap = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .styled-text{
  text-align: right;
  }
`;
