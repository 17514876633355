import React from "react";
import TimeSheet from "molecules/TimeSheet";
import LeftIcon from "assets/icons/UI/left.svg";
import RightIcon from "assets/icons/UI/right.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { DatePicker, Form, Spin, notification } from "antd";
import { useFetch } from "hooks/useFetch";
import queryKeys from "common/queryKey";
import moment from "moment";
import { ClickAwayListener } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserInfo, getUserList } from "redux/selectors";
import { useUpdate } from "hooks/useUpdate";
import { useQueryClient } from "react-query";
import useWeekData, { periodToDate } from "./useWeekData";
import { ConfigProvider } from "antd";
import en_GB from "antd/lib/locale-provider/en_GB";
import "moment/locale/en-gb";
import TimeSheetFooterHint from "./TimeSheetFooterHint";
import TimeSheetOverView from "./TimeSheetOverView";
import { APPROVED, PENDING } from ".";
import useTimesheetfns from "./useTimesheetfns";

moment.locale("en-gb");

const WITHDRAWN = "withdrawn";

const PendingTimeSheet = ({ selectedPeriod }) => {
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedPeriodToDate = periodToDate(selectedPeriod);

  const {
    date,
    week,
    currentWeek,
    edit,
    setEdit,
    currentNiceWeekRange,
    disabledDate,
    handleNextWeek,
    handlePreviousWeek,
    onChange,
  } = useWeekData(selectedPeriodToDate);

  const { getTimesheet, updateTimesheet } = queryKeys;

  const userInfo = useSelector((state) => getUserInfo(state));

  const userList = useSelector(getUserList);

  const currentUser = userList.find((user) => user.userid === userInfo.userid);

  const country = currentUser?.managingoffice?.[0];

  const { data: timesheetDataRes, isLoading, isFetched } = useFetch(
    `${getTimesheet.key}-week:${week}-status:pending`,
    `${getTimesheet.url}?week=${week}&employeeId=${userInfo.employer_id}&status=pending`,
    {
      enabled: !!week,
    }
  );

  const [form] = Form.useForm();

  const { timeSheet: timeSheetData } = useTimesheetfns({
    currentWeek,
    form,
    week,
    timesheetDataRes,
    isFetched,
  });

  const noTimesheet =
    !isLoading && !timesheetDataRes?.response?.response?.timeSheet;

  const { mutate: update, isLoading: isUpdating } = useUpdate(
    updateTimesheet.key,
    updateTimesheet.url
  );

  const handleWithDrawTimeSheet = () => {
    handleClose();
    const data = {
      ...timesheetDataRes?.response?.response,
      timeSheet: timeSheetData.map((sec) => {
        return {
          ...sec,
          status: sec.status === PENDING ? WITHDRAWN : sec.status,
        };
      }),
      status: WITHDRAWN,
    };

    update(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `${getTimesheet.key}-week:${week}-status:pending`
        );
        queryClient.invalidateQueries(`${getTimesheet.key}-week:${week}`);
        notification.success({
          message: "TimeSheet Withdrawn Successfully",
        });
      },
    });
  };

  const isAllApproved = timeSheetData?.every((sec) => sec.status === APPROVED);

  return (
    <Form form={form} initialValues={timeSheetData} layout="vertical">
      <div className="timesheet-header">
        <div>
          <img
            src={LeftIcon}
            alt="left"
            className="left-icon"
            onClick={handlePreviousWeek}
          />
          <img
            src={RightIcon}
            alt="right"
            className="right-icon"
            onClick={handleNextWeek}
          />
          <span className="d-inline-flex justify-content-center align-items-center gap-2">
            <span className="date">Date:</span>{" "}
            {edit ? (
              <ClickAwayListener onClickAway={() => setEdit(false)}>
                <div className="position-relative">
                  <ConfigProvider locale={en_GB}>
                    <DatePicker
                      onChange={onChange}
                      picker="week"
                      value={date}
                      open={true}
                      disabledDate={disabledDate}
                    />
                  </ConfigProvider>
                </div>
              </ClickAwayListener>
            ) : (
              <div className="current-week" onClick={() => setEdit(true)}>
                {currentNiceWeekRange}
              </div>
            )}
          </span>
        </div>
        <IconButton
          id="timesheet-pending-button"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className="icon"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="timesheet-pending-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleClose}>Send Reminder</MenuItem>
          {!isAllApproved && (
            <MenuItem onClick={handleWithDrawTimeSheet}>
              Withdraw Submission
            </MenuItem>
          )}
        </Menu>
      </div>
      <Spin spinning={isLoading || isUpdating}>
        {!isLoading && noTimesheet ? (
          <div className="no-timesheet">
            No Pending Timesheet Found for this Week
          </div>
        ) : (
          <>
            <div className="mb-4">
              <TimeSheetOverView timeSheet={timeSheetData} />
            </div>
            <TimeSheet editable={false} data={timeSheetData} />
            <TimeSheetFooterHint />
          </>
        )}
      </Spin>
    </Form>
  );
};

export default PendingTimeSheet;
