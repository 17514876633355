import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Col, Row as AntRow, Empty, Form } from "antd";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import moment from "moment";
import { filter, find, inRange, some, startCase, uniqBy } from "lodash";
import styled from "styled-components";
import COLORS from "common/Colors";
import { useHistory } from "react-router-dom";
import UIModal from "reusableComponent/UIModal";
import AddDateModal from "./AddDateModal";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { findWeekId } from "utils/Date";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import { getUserInfo, getUserList } from "redux/selectors";
import { useSelector } from "react-redux";

export const StyledAvatar = styled(Avatar)`
  margin-right: 0.5rem;
  margin-bottom: 5px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  color: #000000;
`;

function Row(props) {
  const { row, weekDates, index, dataList, userList, getData } = props;
  const [open, setOpen] = React.useState(false);
  const [openindex, setInndex] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [editDate, setEditDate] = React.useState({});
  const [selectedDate, setSelectedDate] = React.useState({});

  const calculatePercentage = (value) => {
    const total = 8;
    const percentage = (value / total) * 100;
    return percentage;
  };

  const userProfile = find(userList, { userid: row?.userId });
  const profileUrl = userProfile ? userProfile.profileurl : null;
  const usersClientList = filter(dataList, { userId: row?.userId });
  // console.log("Row  usersClientList--->", usersClientList);
  const listEngagementList = uniqBy(usersClientList, "engagementId");

  const history = useHistory(); // Initialize useHistory hook

  const handleCellClick = () => {
    history.push("/engagement"); // Navigate to '/engagement' route when cell is clicked
  };

  const handleOpenModal = (row, weekIndex, date) => {
    const userProfile = find(userList, { userid: row?.userId });
    const profileUrl = userProfile ? userProfile.profileurl : null;
    const rowWithProfile = { ...row, profileurl: profileUrl };
    setEditData(rowWithProfile);
    let maxCount = 0;
    const slicedArray = weekDates.slice(weekIndex, 5);
    slicedArray.forEach((date) => {
      const isWithinAnyRange = some(usersClientList, (entry) => {
        const startDate = moment(entry?.bookedStartDate);
        const endDate = moment(entry?.bookedEndDate).add(1, "day");
        return inRange(date, startDate, endDate);
      });
      if (!isWithinAnyRange) {
        maxCount += 1;
      }
    });
    setSelectedDate(date);

    setEditDate(maxCount);
    setOpenEditModal(true);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          className="pointer"
          onClick={() => {
            setOpen(!open);
            setInndex(index);
          }}
        >
          <div className="profile-area">
            {profileUrl ? (
              <StyledAvatar src={profileUrl} size="default"></StyledAvatar>
            ) : (
              <StyledAvatar size="default">
                {startCase(row?.employeeName).charAt(0)}
              </StyledAvatar>
            )}

            {/* <img className="profile-img" src={row.profile} alt="profile" /> */}
            <span className="profile-name">
              {row?.employeeName}
              <div className="role">{row?.projectRole}</div>
            </span>
          </div>
        </TableCell>
        {weekDates.map((date, dateIndex) => {
          const isBetween = some(usersClientList, (entry) => {
            const startDate = moment(entry?.actualBookedStartDate);
            const endDate = moment(entry?.actualBookedEndDate).add(1, "day");
            return inRange(date, startDate, endDate);
          });

          const isWeekend =
            moment(date).day() === 0 || moment(date).day() === 6;

          const { totalValue } = usersClientList?.reduce(
            (acc, entry) => {
              const startDate = moment(entry?.actualBookedStartDate);
              const endDate = moment(entry?.actualBookedEndDate).add(1, "day");
              if (inRange(date, startDate, endDate)) {
                acc.totalDays += endDate.diff(startDate, "days");
                const value =
                  (parseInt(entry?.personDay || 0) * 8) / acc.totalDays;
                acc.totalValue += value;
              }
              return acc;
            },
            { totalDays: 0, totalValue: 0 }
          );

          return (
            <TableCell
              key={dateIndex}
              className={`percentagedata-cell ${
                isWeekend ? "weekend-cell" : isBetween ? "null-cell" : ""
              }`}
            >
              <span>
                {" "}
                {isBetween && !isWeekend
                  ? Math.round(calculatePercentage(totalValue))
                  : 0}
                %
              </span>
              <span>({isBetween && !isWeekend ? 8 : 0} hrs)</span>
            </TableCell>
          );
        })}
      </TableRow>
      {openindex === index && open && (
        <>
          {listEngagementList?.map((client, index) => (
            <TableRow key={index}>
              <TableCell className="pointer">
                {client?.engagementName}
              </TableCell>
              {weekDates.map((date, weekIndex) => {
                const filteredEnagementList = filter(usersClientList, {
                  engagementId: client?.engagementId,
                });
                const isBetween = some(filteredEnagementList, (entry) => {
                  const startDate = moment(entry?.actualBookedStartDate);
                  const endDate = moment(entry?.actualBookedEndDate).add(
                    1,
                    "day"
                  );
                  return inRange(date, startDate, endDate);
                });

                const isWeekend =
                  moment(date).day() === 0 || moment(date).day() === 6;

                const isWithinAnyRange = some(usersClientList, (entry) => {
                  const startDate = moment(entry?.actualBookedStartDate);
                  const endDate = moment(entry?.actualBookedEndDate).add(
                    1,
                    "day"
                  );
                  return inRange(date, startDate, endDate);
                });

                const { totalValue } = filteredEnagementList?.reduce(
                  (acc, entry) => {
                    const startDate = moment(entry?.actualBookedStartDate);
                    const endDate = moment(entry?.actualBookedEndDate).add(
                      1,
                      "day"
                    );
                    if (inRange(date, startDate, endDate)) {
                      acc.totalDays += endDate.diff(startDate, "days");
                      const value =
                        (parseInt(entry?.personDay || 0) * 8) / acc.totalDays;
                      acc.totalValue += value;
                    }
                    return acc;
                  },
                  { totalDays: 0, totalValue: 0 }
                );

                return (
                  <>
                    {!isBetween && !isWeekend && (
                      <TableCell>
                        {/* {!isWithinAnyRange && (
                          <span
                            className="add-icon text-center"
                            style={addStyle}
                            onClick={() =>
                              handleOpenModal(row, weekIndex, date)
                            }
                          >
                            +
                          </span>
                        )} */}
                      </TableCell>
                    )}
                    {isWeekend && (
                      <TableCell className={"weekend-cell"}></TableCell>
                    )}
                    {!isWeekend && isBetween && (
                      <TableCell colSpan={1} className="data-cell">
                        <div className="data-cell-value">
                          {totalValue % 1 === 0
                            ? Math.round(totalValue) // Show as integer if whole number
                            : totalValue.toFixed(2)}{" "}
                          hr - booked
                        </div>
                        <br />
                        <div
                          className="data-cell-value"
                          style={{ backgroundColor: "rgb(228, 250, 231)" }}
                        >
                          {totalValue % 1 === 0
                            ? Math.round(8 - totalValue) // Show as integer if whole number
                            : (8 - totalValue).toFixed(2)}{" "}
                          hr - available
                        </div>
                      </TableCell>
                    )}
                  </>
                );
              })}
            </TableRow>
          ))}
          <TableRow>
            <TableCell className="addclient" onClick={handleCellClick}>
              +Assign New Engagement
            </TableCell>
            {weekDates.map((date, weekIndex) => {
              const isWeekend =
                moment(date).day() === 0 || moment(date).day() === 6;
              return (
                <>
                  {isWeekend && (
                    <TableCell className={"weekend-cell"}></TableCell>
                  )}
                  {!isWeekend && (
                    <TableCell colSpan={1} className="data-cell"></TableCell>
                  )}
                </>
              );
            })}
          </TableRow>
        </>
      )}
      <UIModal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(!openEditModal);
        }}
        header={<div></div>}
        size="sm"
      >
        <AddDateModal
          goBack={() => setOpenEditModal(!openEditModal)}
          Editdata={editData}
          EditDate={editDate}
          selectedDate={selectedDate}
          getData={getData}
        />
      </UIModal>
      {/* <AlertModal
        isModalOpen={openEditModal}
        handleCancel={() => setOpenEditModal(false)}
        handleOk={handleEdit}
        name={"Are you sure you want to extend the day?"}
        loading={loading}
      /> */}
    </React.Fragment>
  );
}

export default function PeoplePlanning({ userList }) {
  const [weekDates, setWeekDates] = React.useState([]);
  const [dataList, setDataList] = React.useState([]);
  const [uniqDataList, setUniqDataList] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState([]);
  const [selectedEnagement, setSelectedEnagement] = React.useState([]);

  const userInfo = useSelector((state) => getUserInfo(state));

  const managingOffice = userList?.find(
    (user) => user.userid === userInfo?.userid
  )?.managingoffice?.[0];

  const [startDate, setStartDate] = React.useState(moment().startOf("isoWeek"));

  // Function to generate weekdays for the current week
  const generateWeekdays = () => {
    const startOfWeek = moment(startDate).startOf("isoWeek"); // Start of the week as Monday
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(moment(startOfWeek).add(i, "days"));
    }
    return days;
  };

  // Effect to update weekdays when startDate changes
  React.useEffect(() => {
    setWeekDates(generateWeekdays());
  }, [startDate]);

  // Function to handle when the previous button is clicked
  const handlePreviousWeek = () => {
    setStartDate(moment(startDate).subtract(1, "week"));
  };

  // Function to handle when the next button is clicked
  const handleNextWeek = () => {
    setStartDate(moment(startDate).add(1, "week"));
  };

  const { getResourePlanningList } = queryKeys;

  const weekId = findWeekId(weekDates[0], weekDates[6]);

  const concatenatedValue = `${weekId},${selectedEmployee.join(
    ","
  )},${selectedEnagement.join(",")}`;

  const { key, url } = getResourePlanningList(concatenatedValue);

  const {
    data: resourcePlanningList,
    isLoading,
    refetch,
  } = useFetch(
    key,
    url,
    {
      enabled: !!weekId,
    },
    {
      startDate: new Date(moment(weekDates[0]).format("YYYY-MM-DD")),
      endDate: new Date(moment(weekDates[6]).format("YYYY-MM-DD")),
      // startDate: "2020-07-01T00:00:00.000Z",
      // endDate: "2021-06-30T00:00:00.000Z",
      employeeIds: [],
      engagementIds: selectedEnagement,
      weekId: [weekId],
      resourceId: [],
      managingOffice: managingOffice,
    }
  );

  React.useEffect(() => {
    if (resourcePlanningList?.response?.response?.filter(item => item.employeeId !== "NA")) {
      getData(resourcePlanningList?.response?.response?.filter(item => item.employeeId !== "NA"));
    }
  }, [resourcePlanningList]);

  const getData = async (response) => {
    // Remove duplicates by resourceId
    let res = uniqBy(response, "resourceId");

    // Check if managingOffice is "Head Office"
    if (managingOffice !== "Head Office") {
      // Filter only if managingOffice is not "Head Office"
      res = res.filter((val) => val?.managingOffice === managingOffice);
    }

    // Update state with the filtered results
    setDataList(res);
    if (Array.isArray(selectedEmployee) && selectedEmployee.length > 0) {
      res = res.filter((val) => selectedEmployee.includes(val?.userId));
    }
    setUniqDataList(uniqBy(res, "userId"));
  };

  const iconStyle = { padding: "0 10px", margin: "0" };

  const [form] = Form.useForm();

  const handleFormChange = () => {
    const values = form.getFieldsValue();

    setSelectedEmployee(values?.employee || []);
    setSelectedEnagement(values?.engagement || []);
  };

  return (
    <>
      <div className="table-header">
        <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
          <AntRow gutter={16}>
            <Col span={12}>
              <DropdownCheckBox
                form={form}
                name="employee"
                label="Filter by Employee"
                options={uniqBy(
                  dataList?.map((item) => ({
                    label: item.employeeName,
                    value: item.userId,
                  })),
                  "value"
                )}
                handleFormChange={handleFormChange}
              />
            </Col>

            <Col span={12}>
              <DropdownCheckBox
                form={form}
                name="engagement"
                label="Filter by Engagement"
                options={uniqBy(
                  dataList?.map((item) => {
                    return {
                      label: item.engagementName,
                      value: item.engagementId,
                    };
                  }),
                  "engagementId"
                )}
                handleFormChange={handleFormChange}
              />
            </Col>
          </AntRow>
        </Form>
        <div className="text-end pagination">
          <ButtonOutlined style={iconStyle}>
            <KeyboardArrowLeftIcon onClick={handlePreviousWeek} />
          </ButtonOutlined>
          <ButtonOutlined style={iconStyle}>
            <KeyboardArrowRightIcon onClick={handleNextWeek} />
          </ButtonOutlined>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className="header-cell">Employee</TableCell>
              <TableCell className="header-cell" colSpan={8} align="left">
                {moment(weekDates[0]).format("MMMM DD")} -{" "}
                {moment(weekDates[6]).format("MMMM DD")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="header-cell" />
              {weekDates.map((date, idx) => (
                <TableCell
                  key={idx}
                  className={
                    idx === 5 || idx === 6 ? "weekend-cell" : "data-cell-header"
                  }
                >
                  {moment(date).format("ddd DD")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!isLoading &&
              uniqDataList?.map((row, idx) => (
                <>
                  <Row
                    key={row.userId}
                    row={row}
                    index={idx}
                    weekDates={weekDates}
                    dataList={dataList}
                    loading={isLoading}
                    userList={userList}
                    getData={refetch}
                  />
                </>
              ))}
          </TableBody>
        </Table>
        {isLoading && (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={<span>Fetching...</span>}
          ></Empty>
        )}
        {uniqDataList?.length === 0 && !isLoading && <Empty />}
      </TableContainer>

      <div className="w-100 mt-2 d-flex gap-2 align-items-center justify-content-center legend">
        <div className="legend-box available">{null}</div>
        <span>Available</span>
        <div className="legend-box booked">{null}</div>
        <span>Booked</span>
      </div>
    </>
  );
}
