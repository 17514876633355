import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getTempEngagementFormData,
  getUserList,
  getUserInfo,
} from "redux/selectors";
import styled from "styled-components";
import { Required } from "./index.js";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min.js";
import queryKeys from "common/queryKey.js";
import { useFetch } from "hooks/useFetch.js";
import { sumBy } from "lodash";
import { billingOffice } from "common/Constants";
import ResourcePlan from "../EngagementView/ResourcePlan.js";
import SubContractor from "../SubContractor/index.js";
import { currencyAmount } from "utils/functions.js";
import { useMutate } from "hooks/useMutate";
import currencyMasterData from "common/currencyRateMasterData.json";

const labelStyle = {
  width: "150px",
  display: "inline-block",
  textAlign: "start",
};

const FinancialResourcePlanFields = ({
  update,
  setDeletedbookedUserId,
  disabled,
  addResourceandSubDisabled,
  localAddresourceDisable
}) => {
  const form = Form.useFormInstance();
  const history = useHistory();
  const tempEngagementFormData = useSelector((state) =>
    getTempEngagementFormData(state)
  );
  const userList = useSelector((state) => getUserList(state));
  const [budjetChargeout, setBudjetChargeout] = useState(0);
  const [showSubContractorModal, setShowSubContractorModal] = useState(false);
  const [plannedSubcontractor, setPlannedSubcontractor] = useState(0);

  const userInfo = useSelector(getUserInfo);

  const currentUserData = userList?.filter(
    (item) => item.userid === userInfo?.userid
  );

  const userCountry = currentUserData[0].managingoffice[0];

  const billingOfficeOptions = billingOffice
    .filter((office) => {
      if (userCountry === "Head Office") {
        return office;
      } else {
        return office.name.includes(userCountry) || (office.id === 18 && office.name === "BDO Africa Holdings Ltd") || (userCountry === "Uganda"&& office.id === 19);
      }
    })
    .map((office) => {
      return {
        label: office.name,
        value: office.id,
      };
    });

  const currencyOptions = currencyMasterData.map((currency) => {
    return {
      label: currency.TBD_DESC,
      value: currency.TBD_DESC,
    };
  });

  const formValues = form.getFieldsValue(true);

  const handleCurrencyChange = (value) => {
    
    const conversionRate = currencyMasterData.find(
      (currency) => currency.TBD_DESC === value
    )?.TBD_ABBR;

    form.setFieldsValue({ conversion_rate: conversionRate });
    
    const billingAmount = form.getFieldValue("billing_amount");
    const plannedFeeAdjustment = form.getFieldValue("planned_fee_adjustment");

    convertToUSD(billingAmount, conversionRate, "usd_amount");
    convertToUSD(plannedFeeAdjustment, conversionRate, "pfa_usd");
  };

  const handleBillingAmountChange = (e) => {
    const billingAmount = e.target.value;
    const conversionRate = form.getFieldValue("conversion_rate");
    convertToUSD(billingAmount, conversionRate, "usd_amount");
    profitCals();
  };

  const handleConversionRateChange = (e) => {
    const conversionRate = e.target.value;
    const billingAmount = form.getFieldValue("billing_amount");
    const plannedFeeAdjustment = form.getFieldValue("planned_fee_adjustment");

    convertToUSD(billingAmount, conversionRate, "usd_amount");
    convertToUSD(plannedFeeAdjustment, conversionRate, "pfa_usd");
    profitCals();
  };

  const convertToUSD = (amount, conversionRate, targetField) => {
    if (amount && conversionRate) {
      const usdAmount = parseFloat(amount) * parseFloat(conversionRate);
      form.setFieldsValue({ [targetField]: Number(usdAmount.toFixed(2)) });
    } else {
      form.setFieldsValue({ [targetField]: "" });
    }
  };

  const pfaStatusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Closed", value: "closed" },
  ];

  const { id } = useParams();

  const [profitLoss, setProfitLoss] = useState(0);
  const {
    getEngagementById,
    getBillingById,
    getTimeSheetByengagementId,
    addEngagement: addEngagementQuery,
  } = queryKeys;

  const { key: addEngagementKey, url: addEngagementUrl } =
    addEngagementQuery(true);

  const { mutate: addEngagement, isLoading: isPosting } = useMutate(
    addEngagementKey,
    addEngagementUrl
  );

  const { key, url } = getEngagementById(id);

  const { data: engagementDataRes, refetch: engagementRefetch } = useFetch(
    key,
    url,
    {
      enabled: !!id,
    }
  );

  const engagementData = engagementDataRes?.data;

  const isDisabled = 
  engagementData?.jobCloser?.status === "Active" &&
  !["george.ndungu@bdo-ea.com", "priscillah.githanga@bdo-ea.com"].includes(userInfo?.userid);

  useEffect(() => {
    let data =
      engagementData?.employeeList ?? tempEngagementFormData?.resourceList;
    const total = sumBy(data, (item) => parseFloat(item.personDay ?? 0));
    form.setFieldsValue({ mandays: total });
    let subcontractorData =
      engagementData?.subcontractor ?? tempEngagementFormData?.subcontractList;
    const sumSubContractamt = sumBy(subcontractorData, (item) =>
      parseFloat(item?.amount)
    );
    form.setFieldsValue({ subcontractor_usd: parseInt(sumSubContractamt) });

    const budjetChargeout = (
      engagementData?.employeeList ??
      tempEngagementFormData?.resourceList ??
      []
    )
      ?.filter((item) => {
        const resourceExtRate = parseFloat(item?.resourceExtRate);
        return !isNaN(resourceExtRate) && item?.personDay !== undefined;
      })
      .map((item) => parseFloat(item?.resourceExtRate) * item?.personDay * 8)
      .reduce((acc, value) => acc + value, 0);

    // setBudjetChargeout(budjetChargeout);
    setPlannedSubcontractor(sumSubContractamt);
  }, [engagementData, tempEngagementFormData]);

  const { key: billingKey, url: billingUrl } = getBillingById(id);
  const { key: timesheetKey, url: timesheetUrl } =
    getTimeSheetByengagementId(id);

  const { data: billingList } = useFetch(billingKey, billingUrl, {
    enabled: !!id,
  });

  const { data: timeSheetList } = useFetch(timesheetKey, timesheetUrl, {
    enabled: !!id,
  });

  const billingData = useMemo(() => {
    return billingList?.response?.items ?? [];
  }, [billingList]);

  const timeSheetData = useMemo(() => {
    return timeSheetList?.response?.response ?? [];
  }, [timeSheetList]);

  function profitCals() {
    // Calculate the profit loss
    const profitLossCal =
      parseInt(form.getFieldValue("usd_amount")) -
      (parseInt(budjetChargeout) + parseInt(plannedSubcontractor));
    console.log();
    console.log("profitLossCal --->", profitLossCal);

    const conversionRate = form.getFieldValue("conversion_rate");
    form.setFieldsValue({
      planned_fee_adjustment:
        profitLossCal < 0 ? Math.abs(profitLossCal) : "0.00",
    });
    form.setFieldsValue({
      pfa_usd: profitLossCal < 0 ? Math.abs(profitLossCal) : "0.00",
    });

    console.log("profitLossCal 0--->", profitLossCal);
    setProfitLoss(profitLossCal);
  }

  useEffect(() => {
    // Calculate the total billing amount
    profitCals();
    if (!update) {
      const pfastatus = profitLoss < 0 ? "Not Approved" : "Not Applicable";
      form.setFieldsValue({ pfa_status: pfastatus });
      let jobclosurestatus =
        pfastatus === "Not Approved" ? "Not Approved" : "Not Started";
      form.setFieldsValue({ status: jobclosurestatus });
      if (tempEngagementFormData !== undefined) {
        tempEngagementFormData.pfa_status = pfastatus;
        tempEngagementFormData.status = jobclosurestatus;
      }
    }
  }, [
    billingData,
    timeSheetData,
    engagementData,
    budjetChargeout,
    plannedSubcontractor,
    formValues,
  ]);

  const yearRangeOptions = [];
  const startYear = 2000; // Adjust as needed
  const endYear = new Date().getFullYear() + 1;

  for (let year = startYear; year < endYear; year++) {
    yearRangeOptions.push({
      label: `${year}-${year + 1}`,
      value: `${year}-${year + 1}`,
    });
  }

  const handleAddResource = () => {
    const planStartDate = formValues?.plan_start_date;
    const planEndDate = formValues?.plan_end_date;
    const personIncharge = formValues?.person_incharge;
    const partner = formValues?.partner;

    if (planStartDate && planEndDate && personIncharge && partner) {
      history.push(
        `/engagement/${
          engagementData?.engagementId ?? tempEngagementFormData?.engagement_id
        }/add-resources`
      );
    } else {
      notification.error({
        message:
          "Please Select Person In charge, Partner,  Plan Start Date and Plan End Date",
      });
    }
  };

  const handleShowSubcontractorModal = () => {
    const planStartDate = formValues?.plan_start_date;
    const planEndDate = formValues?.plan_end_date;
    const personIncharge = formValues?.person_incharge;
    const partner = formValues?.partner;

    if (planStartDate && planEndDate && personIncharge && partner) {
      setShowSubContractorModal(true);
    } else {
      notification.error({
        message:
          "Please Select Person In charge, Partner,  Plan Start Date and Plan End Date",
      });
    }
  };

  let selectedPartnerName = userList?.find(
    (val) =>
      val?.userid === engagementData?.stakeHolder?.partner ??
      tempEngagementFormData?.partner ??
      null
  );

  let clientName = form.getFieldValue("client_name");

  const engagementApprovalSendEmail = () => {
    const sendEmailData = {
      managingPartner: selectedPartnerName
        ? selectedPartnerName?.firstname + " " + selectedPartnerName?.lastname
        : null,
      clientName: engagementData?.client?.clientName ?? clientName ?? null,
      emailId:
        engagementData?.stakeHolder?.partner ??
        tempEngagementFormData?.partner ??
        null,
    };
    addEngagement(sendEmailData, {
      onSuccess: (res) => {
        notification.success({
          message: "Email Sent Successfully",
        });
      },
    });
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    if (!/^\d$/.test(key) && key !== "Backspace" && key !== ".") {
      e.preventDefault();
    }
  };

  return (
    <FinancialWrap>
      <Card className="fields-card">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={
                <span style={labelStyle}>
                  Billing Entity <Required />
                </span>
              }
              name="billing_office"
              rules={[
                { required: true, message: "Please select billing Entity!" },
              ]}
            >
              <Select
                placeholder="Select"
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={billingOfficeOptions}
                disabled={(userInfo?.userid === engagementData?.stakeHolder?.personIncharge && engagementData?.jobCloser?.status === "Active" || ["george.ndungu@bdo-ea.com", "priscillah.githanga@bdo-ea.com"].includes(userInfo?.userid)) ? false : disabled}
              />
            </Form.Item>

            <Form.Item
              label={
                <span style={labelStyle}>
                  Financial Year <Required />
                </span>
              }
              name="financial_year"
              // rules={[
              //   { required: true, message: "Please select financial year!" },
              // ]}
            >
              {/* <DatePicker picker="year" style={{ width: "100%" }} placeholder="Select" /> */}
              {/* <Select
                placeholder="Select Financial Year"
                options={yearRangeOptions.reverse()}
                style={{ width: "100%" }}
              /> */}
              <Input placeholder="Enter" disabled />
            </Form.Item>

            <Form.Item
              label={<span style={labelStyle}>Mandays</span>}
              name="mandays"
            >
              <Input placeholder="Enter" disabled />
            </Form.Item>

            <Form.Item
              label={<span style={labelStyle}>Currency</span>}
              name="currency"
            >
              <Select
                placeholder="Select"
                options={currencyOptions}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                onChange={handleCurrencyChange}
                disabled={isDisabled}
              />
            </Form.Item>

            <Form.Item
              label={<span style={labelStyle}>Local Currency Amount</span>}
              name="billing_amount"
            >
              <Input
                placeholder="Enter"
                onChange={handleBillingAmountChange}
                disabled={localAddresourceDisable}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
            <Form.Item
              label={<span style={labelStyle}>Conversion rate</span>}
              name="conversion_rate"
            >
              <Input
                placeholder="Enter"
                onChange={handleConversionRateChange}
                disabled={addResourceandSubDisabled}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>

            <Form.Item
              label={<span style={labelStyle}>USD Amount</span>}
              name="usd_amount"
            >
              <Input placeholder="Enter" disabled={true} />
            </Form.Item>
            {/* 
          <Form.Item label={<span style={labelStyle}>Planned Fee Adjustment</span>} name="planned_fee_adjustment">
            <Input placeholder="Enter" disabled />
          </Form.Item>

          <Form.Item label={<span style={labelStyle}>PFA USD</span>} name="pfa_usd">
            <Input placeholder="Enter" disabled={true} />
          </Form.Item> 
            <Form.Item name="usd_amount" style={{ display: "none" }}>
              <Input type="hidden" />
            </Form.Item>
            */}

            <Form.Item
              name="planned_fee_adjustment"
              style={{ display: "none" }}
            >
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="pfa_usd" style={{ display: "none" }}>
              <Input type="hidden" />
            </Form.Item>

            <div className="d-flex align-items-start gap-2">
              <Form.Item
                label={<span style={labelStyle}>PFA Status</span>}
                name="pfa_status"
                className="w-100"
              >
                {/* <Select
                  placeholder="Select"
                  options={pfaStatusOptions}
                  disabled
                /> */}
                <Input placeholder="Enter" disabled={true} />
              </Form.Item>
              {profitLoss < 0 && (
                <Button
                  type="primary"
                  loading={isPosting}
                  onClick={() => {
                    engagementApprovalSendEmail();
                  }}
                  disabled={form.getFieldValue("pfa_status") === "Approved"}
                >
                  Get Approval
                </Button>
              )}
            </div>

            <Form.Item
              style={{ display: "none" }}
              label={<span style={labelStyle}>Subcontractor USD</span>}
              name="subcontractor_usd"
            >
              <Input placeholder="Enter" type="hidden" disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <StyleTable
              bordered
              columns={[
                { title: "Summary", dataIndex: "summary", key: "summary" },
                {
                  title: "Budget(USD)",
                  dataIndex: "manday_fee",
                  key: "manday_fee",
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  summary: "Total Mandays",
                  manday_fee: formValues?.mandays || "0",
                },
                {
                  key: "2",
                  summary: "Billing Amount(USD)",
                  manday_fee:
                    currencyAmount("$", formValues?.usd_amount) ||
                    currencyAmount("$", 0),
                },
                {
                  key: "3",
                  summary: "Planned Chargout",
                  manday_fee:
                    currencyAmount("$", budjetChargeout) ||
                    currencyAmount("$", 0),
                },
                {
                  key: "4",
                  summary: "Planned Subcontractor",
                  manday_fee:
                    currencyAmount("$", parseInt(plannedSubcontractor)) ||
                    currencyAmount("$", 0),
                },
                {
                  key: "5",
                  summary: "Estimated Profit",
                  manday_fee: (
                    <span style={{ color: profitLoss < 0 ? "red" : "black" }}>
                      {((budjetChargeout > 0 || plannedSubcontractor > 0) &&
                        currencyAmount("$", profitLoss)) ||
                        currencyAmount("$", 0)}
                    </span>
                  ),
                },
                {
                  key: "6",
                  summary: "PFA(Planned fee Adjustment)",
                  manday_fee:
                    profitLoss < 0
                      ? currencyAmount("$", Math.abs(profitLoss))
                      : currencyAmount("$", 0),
                },
                {
                  key: "7",
                  summary: "Profit - after PFA",
                  manday_fee:
                    (budjetChargeout > 0 || plannedSubcontractor > 0) &&
                    profitLoss > 0
                      ? currencyAmount("$", Math.abs(profitLoss))
                      : currencyAmount("$", 0),
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
      </Card>

      <div className="mt-3">
        <div className="btns">
          <Button
            type="primary"
            className="add-resource-btn"
            onClick={handleAddResource}
            disabled={localAddresourceDisable}
          >
            Add Resources
          </Button>

          <Button
            type="primary"
            className="add-subcontractor-btn"
            onClick={handleShowSubcontractorModal}
            disabled={localAddresourceDisable}
          >
            Add Subcontractor
          </Button>
        </div>

        <ResourcePlan
          engagementData={engagementData}
          tempEngagementFormData={tempEngagementFormData}
          engagementRefetch={engagementRefetch}
          update={update}
          setDeletedbookedUserId={setDeletedbookedUserId}
          addResourceandSubDisabled={addResourceandSubDisabled}
          setBudjetChargeout={setBudjetChargeout}
        />
        <SubContractor
          engagementData={engagementData}
          setShowAddModal={setShowSubContractorModal}
          showAddModal={showSubContractorModal}
          tempEngagementFormData={tempEngagementFormData}
          engagementRefetch={engagementRefetch}
        />
      </div>
    </FinancialWrap>
  );
};
export default FinancialResourcePlanFields;

const FinancialWrap = styled.div`
  .btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .add-resource-btn,
  .add-subcontractor-btn {
    border-radius: 5px;
  }

  .ant-select-selector {
    cursor: pointer !important;
  }

`;

const StyleTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #dbdbdb;
    font-weight: 700;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table {
    border-top: 1px solid #dbdbdb;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid #dbdbdb;
  }
  .ant-table-cell {
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 500;
  }
  .ant-table-container {
    border-radius: 5px;
  }
  .ant-table {
    border-radius: 5px;
  }
`;
