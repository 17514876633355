import {
  Alert,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Table,
  Upload,
} from "antd";
import Button from "atoms/Button";
import React, { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { ReactComponent as CalendarPickerIcon } from "assets/icons/UI/calendar-picker.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUserInfo, getUserList } from "redux/selectors";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { billingOffice, currencyOptions, STATUS_CODE } from "common/Constants";
import { CALL_API } from "common/API";
import { find } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { ViewClaims } from "./ViewClaims";

const AddNewBilling = () => {
  const userList = useSelector((state) => getUserList(state));
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [base64, setBase64] = useState("");
  const { id } = useParams();
  const { getEngagementById, getClaims } = queryKeys;
  const { key, url } = getEngagementById(id);
  const location = useLocation();
  const { record, viewbillings = false, tabid = "" } = location.state || {};
  const [update, setUpdate] = useState(!!record);
  const [addClaims, setaddClaims] = useState([]);
  const [isViewClims, setIsViewClims] = useState(false);
  const [claimsData, setClaimsData] = useState([]);
  const [internalBillingChecked, setInternalBillingChecked] = useState(false);

  const userInfo = useSelector((state) => getUserInfo(state));

  const handleCheckboxChange = (e) => {
    setInternalBillingChecked(e.target.checked);
  };

  const { data: engagementDataRes } = useFetch(key, url, {
    enabled: !!id,
  });
  const engagementData = engagementDataRes?.data;
  const currentYear = new Date().getFullYear();
  const billingOfficeId = engagementData?.financial_resourcePlan?.billingOffice;
  const billingOfficeName = billingOffice.find(
    (office) => office.id === billingOfficeId
  )?.name;

  const userListOptions = userList.map((user) => {
    return {
      label: user.fullname,
      value: user.userid,
    };
  });

  const { key: claimskey, url: claimsurl } = getClaims(
    engagementData?.engagementId,
    engagementData?.clientId
  );

  const { data: getClaimsData } = useFetch(claimskey, claimsurl);

  let claimsDataSource = getClaimsData?.response?.data;

  const handleClaims = () => {
    setIsModalVisible(true);
  };

  const { key: clientKey, url: clienturl } = queryKeys.getClient(
    engagementData?.clientId
  );

  const { data: clientDataRes } = useFetch(clientKey, clienturl, {
    endpoint: !!engagementData?.clientId,
  });

  const onFinish = async (form) => {
    try {
      setLoading(true);

      const amount =
        parseFloat(form?.amount.replace(/\$/g, "")) /
        parseFloat(
          engagementData?.financial_resourcePlan?.conversion_rate ?? 1
        );

      const payload = {
        clientcode: engagementData?.clientId,
        engagementid: engagementData?.engagementId,
        statusid: "1",
        statusname: "Pending",
        engagementPartner: engagementData?.stakeHolder?.partner,
        billingoffice: billingOfficeName,
        userid: userInfo?.userid,
        billingdate: moment(form?.billingdate).format("YYYY-MM-DD"),
        internalbilling: form?.internalbilling,
        description: form?.description,
        currency: form?.currency,
        // subengagement: form?.subEngagement,
        amount: parseFloat(form?.amount.replace(/\$/g, "")),
        vat: form?.vat,
        financialyear: moment(form?.financialyear).format("YYYY-MM-DD"),
        loerefno: form?.loerefno,
        contactpersonid: form?.contactpersonid,
        contactperson: form?.contactpersonid,
        contactpersonacid: form?.contactpersonacid,
        contactpersonac: form?.contactpersonacid,
        billingfilename: form.file ? form.file[0]?.name : null,
        billingfiletype: form.file ? form.file[0]?.type : null,
        content: base64 ?? null,
        billingfile: update ? record.billingfile : "",
        claims: addClaims,
      };

      const { code } = update
        ? await CALL_API(
            `insyts/billing?engagementid=${record?.engagementid}&billingid=${record?.billingid}`,
            "patch",
            payload
          )
        : await CALL_API(`insyts/billing`, "post", payload);
      setLoading(false);
      if (code === STATUS_CODE.SUCCESS) {
        message.success(update ? "Successfully Updated" : "Successfully Added");
        history.goBack();
      }
    } catch (error) {
      message.error(
        "Something went wrong. Please reach out to our technical team."
      );
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const initialValues = record
    ? {
        billingdate: record.billingdate ? moment(record.billingdate) : moment(),
        internalbilling: record.internalbilling,
        description: record.description || "",
        currency: record.currency || "",
        amount: record.amount || "",
        vat: record.vat || "",
        financialyear: record.financialyear
        ? moment(record.financialyear)
        : null,
        loerefno: engagementData?.client?.loeRefNo || "",
        contactpersonid: record.contactpersonid || "",
        contactpersonacid: record.contactpersonacid || "",
        file: record.billingfile
          ? [{ name: record.billingfile.split("/").pop() }]
          : [],
      }
    : {
        billingdate: moment(),
        currency: engagementData?.financial_resourcePlan?.currency,
        loerefno: engagementData?.client?.loeRefNo || "",
        contactpersonid: clientDataRes?.data[0]?.primary_contact || "",
        contactpersonacid: clientDataRes?.data[0]?.primary_contact || "",
      };

  useEffect(() => {
    setaddClaims(record?.claims);
  }, []);

  const disablePreviousDates = (current) => {
    return current && current < moment().startOf("day"); // Disable dates before today
  };
  const dataSource = [
    {
      key: 1,
      actionStatus: "Date",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{record?.billingdate}</>
          ) : (
            <Form.Item
              label=""
              name="billingdate"
              rules={[
                {
                  required: true,
                  message: "Please select Date!",
                },
              ]}
            >
              <DatePicker
                // picker="year"
                style={{ width: "100%" }}
                placeholder="Select"
                disabledDate={disablePreviousDates}
              />
            </Form.Item>
          )}

          <Form.Item
            name="internalbilling"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox disabled={viewbillings} onChange={handleCheckboxChange}>
              Internal Billing
            </Checkbox>
          </Form.Item>
          {internalBillingChecked && (
            <span className="text-success text-center">
              This bill will not be sent to finance; it will be sent to the
              partner only.
            </span>
          )}
        </>
      ),
    },
    {
      key: 2,
      actionStatus: "Description",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{record?.description}</>
          ) : (
            <Form.Item
              label=""
              name="description"
              rules={[
                { required: true, message: "Please input your description!" },
              ]}
            >
              <Input.TextArea
                bordered={false}
                placeholder="Description"
                rows={2}
                style={{
                  resize: "none",
                }}
              />
            </Form.Item>
          )}
        </>
      ),
    },
    // {
    //   key: 3,
    //   actionStatus: "Sub Engagements",
    //   approvedbyPartner: (
    //     <>
    //       <Form.Item
    //         label=""
    //         name="subEngagement"
    //         // rules={[
    //         //   {
    //         //     required: true,
    //         //     message: "Please enter Contact Person!",
    //         //   },
    //         // ]}
    //       >
    //         <Select
    //           placeholder="Select"
    //           showSearch={true}
    //           />
    //           </Form.Item>
    //         </>
    //       ),
    // },
    {
      key: 4,
      actionStatus: "Currency",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{record?.currency}</>
          ) : (
            <Form.Item
              label=""
              rules={[
                {
                  required: true,
                  message: "Currency is required.",
                },
              ]}
              name="currency"
            >
              {/* <Select placeholder="Select" options={currencyOptions} /> */}
              <Input placeholder="Enter" disabled />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      key: 5,
      actionStatus: "Amount",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{record?.amount}</>
          ) : (
            <Form.Item
              label=""
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Amount is required.",
                },
              ]}
            >
              <Input placeholder="Enter" />
            </Form.Item>
          )}

          <Form.Item label="" name="vat">
            <Radio.Group disabled={viewbillings}>
              <Radio value="VAT">VAT</Radio>
              <Radio value="NON Vat">NON Vat</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ),
    },
    {
      key: 6,
      actionStatus: "Financial Year",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{moment(record?.financialyear).format("YYYY")}</>
          ) : (
            <Form.Item
              label=""
              name="financialyear"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select financial year!",
              //   },
              // ]}
            >
              <Input placeholder="Enter" disabled defaultValue={currentYear}/>
            </Form.Item>
          )}
        </>
      ),
    },
    {
      key: 7,
      actionStatus: "LOE Ref No.",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{record?.loerefno}</>
          ) : (
            <Form.Item
              label=""
              name="loerefno"
              rules={[
                {
                  required: true,
                  message: "LOE Ref No is required.",
                },
              ]}
            >
              <Input placeholder="Enter" />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      key: 8,
      actionStatus: "Contact Person",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{record?.contactpersonid}</>
          ) : (
            <Form.Item
              label=""
              name="contactpersonid"
              rules={[
                {
                  required: true,
                  message: "Please enter Contact Person!",
                },
              ]}
            >
              <Select
                placeholder="Select"
                disabled
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                showSearch={true}
                options={userListOptions}
              />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      key: 9,
      actionStatus: "Contact Person A/C",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{record?.contactpersonacid}</>
          ) : (
            <Form.Item
              label=""
              name="contactpersonacid"
              rules={[
                {
                  required: true,
                  message: "Please enter Contact Person A/C",
                },
              ]}
            >
              <Select
                placeholder="Select"
                disabled
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                showSearch={true}
                options={userListOptions}
              />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      key: 10,
      actionStatus: "File Name",
      approvedbyPartner: (
        <>
          {viewbillings ? (
            <>{new URL(record?.billingfile).pathname.split("/").pop()}</>
          ) : (
            <Form.Item
              label=""
              name="file"
              valuePropName="fileList"
              getValueFromEvent={(e) =>
                Array.isArray(e) ? e : e && e.fileList
              }
              rules={[
                {
                  required: false,
                  message: "Please upload a file.",
                },
              ]}
            >
              <Upload
                showUploadList={true}
                beforeUpload={(file) => {
                  // Return false to prevent multiple file uploads
                  return !form.getFieldValue(file);
                }}
                maxCount={1}
                customRequest={({ file, onSuccess }) => {
                  // Read the file and convert it to base64
                  const reader = new FileReader();
                  reader.readAsDataURL(file);

                  reader.onload = () => {
                    // Get the base64 data
                    const base64Data = reader.result.split(",")[1];
                    setBase64(base64Data);

                    // Call onSuccess to indicate successful upload
                    onSuccess();

                    // Pass the base64 data to the onChange function
                  };

                  reader.onerror = (error) => {
                    console.error("File read error:", error);
                    // Handle error if needed
                  };
                }}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          )}
        </>
      ),
    },
    {
      key: 11,
      actionStatus: "Select Claims",
      approvedbyPartner: (
        <>
          <Form.Item label="" name="selectclaims">
            {(addClaims?.length > 0 || viewbillings) && (
              <Button
                style={{ backgroundColor: "#E8E8E8", border: "none" }}
                loading={loading}
                onClick={() => setIsViewClims(true)}
              >
                View Claims
              </Button>
            )}
            {!viewbillings && (
              <Button type="primary" loading={loading} onClick={handleClaims}>
                Add Claims
              </Button>
            )}
          </Form.Item>

          <ViewClaims
            isModalVisible={isModalVisible || isViewClims}
            viewClims={isViewClims ? true : false}
            setIsModalVisible={
              isModalVisible ? setIsModalVisible : setIsViewClims
            }
            setaddClaims={setaddClaims}
            addClaims={addClaims}
            claimsDataSource={
              tabid
                ? record?.claims
                : isViewClims
                ? addClaims
                : claimsDataSource
            }
            isViewClims={isViewClims}
            update={update}
          />
        </>
      ),
    },
  ];

  const printComponentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const [form] = Form.useForm();
  const cursorStyle = { cursor: "pointer" };
  const history = useHistory();

  return (
    <BillingWrap>
      {/* <h6><span
        style={cursorStyle}
        className="back"
        onClick={() => {
          history.goBack();
        }}>Back </span> {">"} Add Bill</h6> */}
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h6>
              <span
                style={cursorStyle}
                className="back"
                onClick={() => {
                  if (tabid) {
                    history.push("/approvals", { tabId: tabid });
                  } else {
                    history.goBack();
                  }
                }}
              >
                Back
              </span>{" "}
              {">"}{" "}
              {update ? (viewbillings ? "View Bill" : "Edit Bill") : "Add Bill"}
            </h6>
          </div>
          <div style={{ display: "flex", gap: "8px" }} className="mb-3">
            {!viewbillings && (
              <div>
                <Button type="primary" className="ms-0" onClick={handlePrint}>
                  Print Invoice
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
        <div ref={printComponentRef}>
          <StyledTable
            columns={[
              {
                title: "Action/Status",
                dataIndex: "actionStatus",
                key: "actionStatus",
                width: "30%",
                className: "action-status",
              },
              {
                title: `${
                  update || viewbillings ? record?.statusname : "pending"
                }`,
                dataIndex: "approvedbyPartner",
                key: "approvedbyPartner",
                width: "70%",
              },
            ]}
            dataSource={dataSource}
            pagination={false}
          />
        </div>
      </Form>
    </BillingWrap>
  );
};

export default AddNewBilling;

const BillingWrap = styled.div`
  .date-render-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px 14px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;
