import { Col, Row, Table } from "antd";
import Title from "atoms/Title";
import COLORS from "common/Colors";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import React, { useEffect, useMemo, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import Button from "atoms/Button";
import { useSelector } from "react-redux";
import { getManagingOffice, getUserList } from "redux/selectors";
import { find, sumBy } from "lodash";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import { currencyAmount } from "utils/functions";

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border: 2px solid #000; */
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
  .graybg {
    background-color: #f6f6f6;
  }
  .label_Color {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3019;
    color: #000;
  }
  .label_value_Color {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
  }
  .employess_detail_values {
    /* border-bottom: 1px solid #ccc; */
  }
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px 14px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;

function FinancialSummary() {
  const { id } = useParams();

  const [profitLoss, setProfitLoss] = useState(0);
  const { getEngagementById, getBillingById, getTimeSheetByengagementId } =
    queryKeys;
  const { key, url } = getEngagementById(id);

  const { data: engagementDataRes } = useFetch(key, url, {
    enabled: !!id,
  });
  const [loading, setLoading] = useState(false);
  const printComponentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const handlePrintWithDelay = () => {
    setLoading(true);
    setTimeout(() => {
      handlePrint();
      setLoading(false);
    }, 2000); // 2-second delay
  };
  const engagementData = engagementDataRes?.data;
  const history = useHistory();
  const cursorStyle = { cursor: "pointer" };
  const [budjetChargeout, setBudjetChargeout] = useState("");

  const manageoffice = useSelector((state) => getManagingOffice(state));
  const userList = useSelector((state) => getUserList(state));

  const { key: billingKey, url: billingUrl } = getBillingById(id);
  const { key: timesheetKey, url: timesheetUrl } =
    getTimeSheetByengagementId(id);

  const { data: billingList } = useFetch(billingKey, billingUrl, {
    enabled: !!id,
  });

  const { data: timeSheetList } = useFetch(timesheetKey, timesheetUrl, {
    enabled: !!id,
  });

  const billingData = useMemo(() => {
    return (
      billingList?.response?.items?.filter(
        (val) => val?.statusname === "Approved by Partner"
      ) ?? []
    );
  }, [billingList]);

  const billingDisbursement = sumBy(billingData, (item) => {
    return sumBy(item.claims, (claim) => parseFloat(claim.amount));
  });

  const timeSheetData = useMemo(() => {
    return timeSheetList?.response?.response ?? [];
  }, [timeSheetList]);

  useEffect(() => {
      // Calculate the total billing amount
      const totalBilling = sumBy(
        billingData,
        (item) => parseFloat(item?.amount) || 0
      );
      // Calculate the total timeSheet cost
      const totalActualChargeoutAmount = sumBy(
        timeSheetData,
        (item) =>
          (parseFloat(item?.actualMandays) || 0) * (parseFloat(item?.rate) || 0)
      );
  
      // Calculate total claims
      const totalClaims = sumBy(
        timeSheetData,
        (item) => parseFloat(item?.claimsDisbursement) || 0
      );
  
      // Calculate the total subcontractor amount
      const totalSubcontractorAmount = sumBy(timeSheetData, item => parseFloat(item?.claimsSubcontract) || 0)
  
       // Calculate the total Bill Disbursement amount
      const totalBillDisbursementAmount = billingDisbursement
  
      // Calculate the profit loss
      // Actual Calculation New flow
      // Billing amounts + Bill Disbsustment (New row which is coming from Billing Claims ) - Chargeout (Timehsset) - Claims (Both Subcont + dissbuontm 
      const profitLoss = totalBilling + totalBillDisbursementAmount - totalActualChargeoutAmount - (totalClaims + totalSubcontractorAmount);
  
      setProfitLoss(profitLoss);
  }, [billingData, timeSheetData, engagementData]);

  useEffect(() => {
    const budjetChargeout = (
      engagementData?.employeeList
    )
      ?.filter((item) => {
        const resourceExtRate = parseFloat(item.resourceExtRate);
        return !isNaN(resourceExtRate) && item.personDay !== undefined;
      })?.map((item) => parseFloat(item.resourceExtRate) * item.personDay * 8)
      .reduce((acc, value) => acc + value, 0);

    setBudjetChargeout(budjetChargeout);
  }, [engagementData]);

  const budgetedProfit =
  parseInt(engagementData?.financial_resourcePlan?.billingAmount) -
  (parseInt(budjetChargeout) + parseInt(
    sumBy(
      (engagementData?.subcontractor)?.map(item => ({
        ...item,
        amount: parseInt(item.amount, 10) || 0 // Convert to integer, default to 0 if NaN
      })),
      "amount"
    )));

  const Information = [
    {
      name: "Engagement Title",
      value: engagementData?.information?.jobTitle
        ? engagementData?.information?.jobTitle
        : "-",
    },
    {
      name: "Managing Office",
      value: engagementData?.client?.managingOffice,
    },
    {
      name: "Manager",
      value: find(userList, {
        userid: engagementData?.stakeHolder?.personIncharge,
      })?.fullname
        ? find(userList, {
            userid: engagementData?.stakeHolder?.personIncharge,
          })?.fullname
        : "-",
    },
    {
      name: "Partner",
      value: find(userList, { userid: engagementData?.stakeHolder?.partner })
        ?.fullname
        ? find(userList, { userid: engagementData?.stakeHolder?.partner })
            ?.fullname
        : "-",
    },
    {
      name: "Client",
      value: engagementData?.client?.clientName ? engagementData?.client?.clientName : "-",
    },
    {
      name: "Competency",
      value: engagementData?.client?.competency,
    },
    {
      name: "Plan Start Date",
      value: engagementData?.stakeHolder?.planStartDate
        ? engagementData?.stakeHolder?.planStartDate
        : "-",
    },
    {
      name: "Plan End Date",
      value: engagementData?.stakeHolder?.planEndDate
        ? engagementData?.stakeHolder?.planEndDate
        : "-",
    },
    {
      name: "Client FY Month",
      value: engagementData?.client?.clientFyMonth
        ? engagementData?.client?.clientFyMonth
        : "-",
    },
  ];

  const Financial = [
    {
      name: "Planned Billing",
      value:
        currencyAmount(
          "$",
          engagementData?.financial_resourcePlan?.billingAmount
        ) || currencyAmount("$", 0),
    },
    {
      name: "Actual Billing",
      value:
        currencyAmount("$", sumBy(billingData, "amount")) ??
        currencyAmount("$", 0),
    },
    {
      name: "Actual Chargeout",
      value:
        currencyAmount(
          "$",
          sumBy(
            timeSheetData,
            (item) => (parseFloat(item?.actualMandays) || 0) * (parseFloat(item?.rate) || 0)
          )
        ) || currencyAmount("$", 0),
    },
    {
      name: "Disbursement (Claims)",
      value:
      timeSheetData?.length > 0
      ? currencyAmount(
          "$",
          sumBy(timeSheetData, item => item?.claimsSubcontract || 0)
        )
      : currencyAmount("$", 0),
    },
    {
      name: "Subcontract (Claims)",
      value:
        currencyAmount("$",  sumBy(timeSheetData, item => item?.claimsSubcontract || 0)) ??
        currencyAmount("$", 0),
    },
    {
      name: "Profit",
      value:
        profitLoss 
          ? currencyAmount("$", profitLoss)
          : currencyAmount("$", 0),
    },
    {
      name: "PFA(Planned fee Adjustment) USD",
      value:
      budgetedProfit < 0
      ? currencyAmount("$", Math.abs(budgetedProfit))
      : currencyAmount("$", 0),
    },
    // {
    //     name: "Profit/Loss",
    //     value: "-"
    // }
  ];

  const Timesheet = [
    {
      name: "Planned Manhours",
      value: sumBy(engagementData?.employeeList, (employee) => parseFloat(employee?.personDay || 0)) * 8 ?? "0.00",
    },
    {
      name: "Actual Manhours",
      value: sumBy(timeSheetData, "actualMandays") ?? "0.00",
    },
    {
      name: "Variance",
      value:
      parseFloat(sumBy(engagementData?.employeeList, (employee) => parseFloat(employee?.personDay || 0)) * 8) -
      parseFloat(sumBy(timeSheetData, "actualMandays")),
    },
  ];

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
    },
    {
      title: "Role",
      dataIndex: "projectRole",
      key: "projectRole",
    },
    {
      title: "Planned Manhours",
      dataIndex: "personDay",
      key: "personDay",
      render: (val) => {

        if (!val) return 0;
        
        return parseFloat(val) * 8;
      },
    },
    {
      title: "Actual Manhours",
      dataIndex: "personDay",
      key: "personDay",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "Rate",
      dataIndex: "resourceExtRate",
      key: "resourceExtRate",
      render: (val) => {
        return currencyAmount("$", val) ?? "-";
      },
    },
    {
      title: "USD Amount",
      dataIndex: "amount",
      key: "amount",
      render: (val, record) => {
        return (
          currencyAmount(
            "$",
            parseFloat(record?.resourceExtRate ?? 0) *
              parseFloat(record?.actualMandays ?? 0)
          ) ?? currencyAmount("$", val)
        );
      },
    },
    {
      title: "Claim",
      dataIndex: "claims",
      key: "claims",
      render: (val) => {
        return val ?? "-";
      },
    },
  ];

  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      {engagementData?.information?.jobTitle}
    </label>,
    <Typography key="3" color="text.primary">
      Financial Summary
    </Typography>,
  ];

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>

      <div className="text-end">
        <Button
          type="primary"
          className="ms-0"
          loading={loading}
          onClick={handlePrintWithDelay}
        >
          Print
        </Button>
      </div>
      <div ref={printComponentRef} className="px-1">
        <h6>Information</h6>
        <StyledTable
          showHeader={false}
          columns={[
            {
              title: "Information",
              dataIndex: "name",
              key: "name",
              width: "30%",
              className: "action-status",
            },
            {
              title: "",
              dataIndex: "value",
              key: "value",
              width: "70%",
            },
          ]}
          dataSource={Information}
          pagination={false}
        />
        <br />
        <h6>Financial (USD)</h6>
        <StyledTable
          showHeader={false}
          columns={[
            {
              title: "",
              dataIndex: "name",
              key: "name",
              width: "30%",
              className: "action-status",
            },
            {
              title: "",
              dataIndex: "value",
              key: "value",
              width: "70%",
            },
          ]}
          dataSource={Financial}
          pagination={false}
        />
        <br />
        <h6>Timesheet</h6>
        <StyledTable
          showHeader={false}
          columns={[
            {
              title: "",
              dataIndex: "name",
              key: "name",
              width: "30%",
              className: "action-status",
            },
            {
              title: "",
              dataIndex: "value",
              key: "value",
              width: "70%",
            },
          ]}
          dataSource={Timesheet}
          pagination={false}
        />
        <br />
        <StyledTable
          columns={columns}
          pagination={false}
          dataSource={Array.from(
            new Map(
                engagementData?.employeeList?.filter((item) => item?.userId !== null)
                    .map(item => [item.resourceId, item])
            ).values()
          ) || []}
        />
      </div>
    </div>
  );
}

export default FinancialSummary;
